import React from "react";

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";

export default function LoginCard({
  back,
  children,
  forward,
  loading,
  mode,
  title,
}) {
  const { bmapi } = useBmapi();
  const classes = styles.useStyles();

  return (
    <Container
      maxWidth="xs"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box textAlign="center" mb={3} mt={6}>
        <img
          src={bmapi.logo()}
          alt="main-logo"
          style={{ maxWidth: "100%", width: bmapi.themeConf.logoWidth }}
        />
      </Box>
      <Box textAlign="center" mt={3} mb={1}>
        <Card>
          <CardContent>
            {title && (
              <Typography component="h1" variant="h5">
                {title}
              </Typography>
            )}
            {children}
          </CardContent>
          {loading && <LinearProgress />}
        </Card>
      </Box>

      {(back || forward) && (
        <Box mt={4} mb={3}>
          <Grid
            container
            justify="space-between"
            className={classes.loginColor}
          >
            <Grid item>{back}</Grid>
            <Grid item>{forward}</Grid>
          </Grid>
        </Box>
      )}

      {bmapi.settings.poweredBy !== false && (
        <Box textAlign="center" pt={2} mb={1} style={{ marginTop: "auto" }}>
          {mode && (
            <Box pt={2} mb={2} textAlign="center">
              {mode}
            </Box>
          )}
          <Box
            textAlign="center"
            mt={1}
            mb={1}
            style={{
              opacity: 0.65,
            }}
          >
            <Link href={bmapi.settings.poweredByLink} target="_blank">
              <Typography
                className={classes.loginColor}
                gutterBottom
                variant="body2"
              >
                <i>{bmapi.settings.poweredBy}</i>
              </Typography>
            </Link>
          </Box>
        </Box>
      )}
    </Container>
  );
}
