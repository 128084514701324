import { defineMessages } from "react-intl";

export const activation = defineMessages({
  title: {
    id: "activation.title",
    defaultMessage: "Attivazione account",
  },
  completeProfile: {
    id: "activation.completeProfile",
    defaultMessage: "Completa il profilo",
  },
  acceptTos: {
    id: "activation.acceptTos",
    defaultMessage: "Approvazione dei Termini di Servizio",
  },
  acceptTosError: {
    id: "activation.acceptTosError",
    defaultMessage:
      "Per proseguire è necessario accettare i termini di servizio",
  },
  acceptTosPart1: {
    id: "activation.acceptTosPart1",
    defaultMessage:
      "Dichiaro di essere consapevole che per utilizzare l’applicazione è necessaria la connessione internet (eventuali costi in base al piano tariffario del proprio Operatore).",
  },
  acceptTosPart2: {
    id: "activation.acceptTosPart2",
    defaultMessage:
      "Dichiaro di aver compiuto 18 anni di età (in caso contrario si è invitati ad uscire dall’applicazione).",
  },
  acceptTosPart3: {
    id: "activation.acceptTosPart3",
    defaultMessage:
      "Dichiaro di avere letto e accettato i Termini di Servizio (utilizzando questo servizio ci si impegna ad osservare le norme contenute nei Termini di Servizio <tosLink>qui</tosLink> descritti)",
  },
  accept: {
    id: "activation.accept",
    defaultMessage: "Accetto",
  },
  dontAccept: {
    id: "activation.dontAccept",
    defaultMessage: "Non accetto",
  },
  agree: {
    id: "activation.agree",
    defaultMessage: "Accetto",
  },
  dontAgree: {
    id: "activation.dontAgree",
    defaultMessage: "Non accetto",
  },
  agreeTos: {
    id: "activation.agreeTos",
    defaultMessage: "Accetto",
  },
  dontAgreeTos: {
    id: "activation.dontAgreeTos",
    defaultMessage: "Non accetto",
  },
  agreePrivacy: {
    id: "activation.agreePrivacy",
    defaultMessage: "Accetto",
  },
  dontAgreePrivacy: {
    id: "activation.dontAgreePrivacy",
    defaultMessage: "Non accetto",
  },
  agreeDirect: {
    id: "activation.agreeDirect",
    defaultMessage: "Accetto",
  },
  dontAgreeDirect: {
    id: "activation.dontAgreeDirect",
    defaultMessage: "Non accetto",
  },
  agreeIndirect: {
    id: "activation.agreeIndirect",
    defaultMessage: "Accetto",
  },
  dontAgreeIndirect: {
    id: "activation.dontAgreeIndirect",
    defaultMessage: "Non accetto",
  },
  privacyError: {
    id: "activation.privacyError",
    defaultMessage:
      "Per proseguire è necessario acconsentire il trattamento dei dati personali",
  },
  privacyTitle: {
    id: "activation.privacyTitle",
    defaultMessage:
      "Approvazione norme e modalità che regolano l’utilizzo dei propri dati personali",
  },
  privacyPart1: {
    id: "activation.privacyPart1",
    defaultMessage:
      "Accedi <privacyLink>qui</privacyLink> per leggere la descrizione del trattamento effettuata sui tuoi dati personali.",
  },
  privacyPart2: {
    id: "activation.privacyPart2",
    defaultMessage:
      "Acconsento al <strong>trattamento dei miei dati personali</strong> <u>sopra</u> descritto <strong>al fine di consentire l’erogazione del servizio</strong> <u>(Obbligatorio)</u>.",
  },
  directMarketing: {
    id: "activation.directMarketing",
    defaultMessage:
      "Acconsento al trattamento dei miei dati personali <u>sopra</u> descritto per <strong>finalità di marketing dirette da parte del circuito {programName}</strong>. A titolo di esempio ricevere <strong>promozioni e coupon di sconto offerti</strong> <u>(Facoltativo)</u>.",
  },
  indirectMarketing: {
    id: "activation.indirectMarketing",
    defaultMessage:
      "Acconsento al trattamento dei miei dati personali <u>sopra</u> descritto per <strong>finalità di marketing eseguite da terze parti collegate al circuito {programName}</strong> <u>(Facoltativo)</u>.",
  },
  confirm: {
    id: "activation.confirm",
    defaultMessage: "Confermo quanto dichiarato sopra",
  },
  birthdayError: {
    id: "activation.birthdayError",
    defaultMessage:
      "Per proseguire è necessario inserire la propria data di nascita",
  },
});
