import React from "react";
import { useIntl } from "react-intl";

import SignInForm from "../../ui/SignInForm";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function SignIn() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();

  function onSubmit(email, password) {
    startLoading();
    return bmapi.doSignInWithEmailAndPassword(email, password).catch((e) => {
      stopLoading();
      notifyError(getErrorMessageString(e, intl));
    });
  }

  return <SignInForm onSubmit={onSubmit} routes={MANAGER_ROUTES} />;
}
