import { DEFAULT_MANIFEST, MULTIPLE_TENANTS } from "./constants";

export function setupMeta(api) {
  const manifest = require(`../configurations/manifest/${api.settings.manifest}.json`);

  const getTitle = () =>
    `${manifest.name || DEFAULT_MANIFEST.name}${
      process.env.NODE_ENV !== "production" ? ` [${process.env.NODE_ENV}]` : ""
    }`;

  document.title = getTitle();

  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute(
      "content",
      manifest.theme_color ||
        api.themeConf.primary ||
        DEFAULT_MANIFEST.theme_color
    );

  if (manifest.favicon) {
    document
      .querySelector('link[rel="shortcut icon"]')
      .setAttribute(
        "href",
        manifest.favicon.includes("://")
          ? manifest.favicon
          : `${window.location.origin}/static/tenants/${api.tenant}/${manifest.favicon}`
      );
  }

  document.getElementById("manifest").setAttribute(
    "href",
    URL.createObjectURL(
      new Blob(
        [
          JSON.stringify({
            ...DEFAULT_MANIFEST,
            ...manifest,
            icons:
              manifest.icons?.map((i) => ({
                ...i,
                src: i.src.includes("://")
                  ? i.src
                  : `${window.location.origin}${api.tenantStaticUrl}${i.src}`,
              })) ||
              DEFAULT_MANIFEST.icons.map((i) => ({
                ...i,
                src: i.src.includes("://")
                  ? i.src
                  : `${window.location.origin}${i.src}`,
              })),
            name: getTitle(),
            scope: `${window.location.origin}/${
              MULTIPLE_TENANTS ? `${api.tenant}/` : ""
            }`,
            start_url: `${window.location.origin}/${
              MULTIPLE_TENANTS ? `${api.tenant}/` : ""
            }?source=pwa`,
            theme_color:
              manifest.theme_color ||
              api.themeConf.primary ||
              DEFAULT_MANIFEST.theme_color,
          }),
        ],
        { type: "application/json" }
      )
    )
  );
}
