import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Box, Button } from "@material-ui/core";

import { getAvailability } from "../utils/campaigns";
import { CONSUMER_ROUTES, PRODUCT_TYPES } from "../utils/constants";
import Product from "./Product";
import Grid from "./Grid";

function available(p) {
  return (
    getAvailability(p) ||
    p.campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD
  );
}

function notAvailable(p) {
  return !available(p);
}

function sameCampaign(campaign) {
  return (p) => p.campaign_id === campaign.campaign.id;
}

function campaignLink(campaign) {
  return CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.campaign.id);
}

function SingleProduct(products, updateData) {
  const Component = (campaign) => {
    const prods = useMemo(() => {
      return products.filter(sameCampaign(campaign));
    }, [campaign]);

    return (
      <Product
        key={campaign.campaign.id}
        campaign={campaign.campaign}
        products={prods}
        availability={getAvailability(campaign)}
        onUpdate={updateData}
        link={campaignLink(campaign)}
      />
    );
  };
  return Component;
}

export default function ProductsList({
  products = [],
  stats = [],
  updateData,
}) {
  const [showAll, setShowAll] = useState(false);

  const itemsAvailable = stats.filter(available);
  const itemsNotAvailable = stats.filter(notAvailable);

  const Renderer = SingleProduct(products, updateData);

  return (
    <React.Fragment>
      <Grid items={itemsAvailable} render={Renderer} />
      {itemsNotAvailable.length > 0 &&
        (showAll ? (
          <Grid items={itemsNotAvailable} render={Renderer} />
        ) : (
          <Box my={4}>
            <Button
              variant="contained"
              color="default"
              onClick={() => setShowAll(true)}
            >
              <FormattedMessage
                id="components.productList.showAll"
                defaultMessage="Mostra tutte le card e coupon"
              />
            </Button>
          </Box>
        ))}
    </React.Fragment>
  );
}
