import React from "react";
import { FormattedMessage } from "react-intl";

import { Box, Container } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useBmapi } from "../utils/bmapi-context";

export default function Content({ children }) {
  const { bmapi } = useBmapi();

  const daysLeft = () =>
    Math.floor(
      bmapi.settings.unverified -
        (new Date() - new Date(bmapi.user.metadata.creationTime)) / 864e5
    );

  return (
    <Box my={5} pb={10}>
      {bmapi.needsVerification() && (
        <Container maxWidth="sm">
          <Box mb={5}>
            <Alert severity="warning">
              <AlertTitle>
                <FormattedMessage
                  id="component.content.notVerifiedAccount"
                  defaultMessage="Account non verificato"
                />
              </AlertTitle>
              {typeof bmapi.settings.unverified === "number" ? (
                <FormattedMessage
                  id="component.content.certifyMessageDays"
                  defaultMessage={`Hai ancora {daysLeft} giorni per certificare il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                  values={{ daysLeft: daysLeft() }}
                />
              ) : (
                <FormattedMessage
                  id="component.content.certifyMessage"
                  defaultMessage={`Certifica il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                />
              )}
            </Alert>
          </Box>
        </Container>
      )}

      {children}
    </Box>
  );
}
