import React from "react";
import { Route } from "react-router-dom";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";

export default function PublicPage({ component: Page, ...routeProps }) {
  const { bmapi } = useBmapi();
  return (
    <Route {...routeProps}>
      <MuiThemeProvider theme={bmapi.theme.login}>
        <CssBaseline />
        <Page />
      </MuiThemeProvider>
    </Route>
  );
}
