import React, { useCallback, useContext, useEffect, useState } from "react";

import BmarkenAPI from "./bmapi";
import { ERRORS, MANAGER_NAMESPACE, VARIANTS } from "./constants";
import { setupMeta } from "./meta";
import pagesLoader from "./pages";

const bmapiContext = React.createContext(null);

export const useBmapi = () => useContext(bmapiContext);

export const BmapiProvider = ({ children, tenant, app, baseUrl }) => {
  const [bmapi, setBmapi] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [pages, setPages] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);

  const notifyError = useCallback((text) => {
    setShowMessage(true);
    setMessage({ text, type: VARIANTS.ERROR });
  }, []);
  const notifySuccess = useCallback((text) => {
    setShowMessage(true);
    setMessage({ text, type: VARIANTS.SUCCESS });
  }, []);
  const hideMessage = useCallback(() => setShowMessage(false), []);

  const startLoading = useCallback(() => setLoading(true), []);
  const stopLoading = useCallback(() => setLoading(false), []);
  const toggleLoading = useCallback(
    (flag) => setLoading((l) => (typeof flag === "boolean" ? flag : !l)),
    []
  );

  useEffect(() => {
    if (bmapi) {
      setPages(pagesLoader(bmapi, bmapi.app, bmapi.userStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmapi?.app, bmapi?.userStatus]);

  useEffect(() => {
    try {
      const api = new BmarkenAPI(tenant, app);
      setBmapi(api);
      setupMeta(api);

      return api.onAuthStateChanged((user) => {
        setUser(user);

        if (
          !user &&
          api.getTenantData().logout &&
          api.settings.customLogout &&
          api.isConsumer()
        ) {
          api.setTenantData({ logout: false });
          window.location.href = api.settings.customLogout.replace(
            "{cbUrl}",
            baseUrl
          );
        }
      });
    } catch (error) {
      if (error.message === ERRORS.ONLY_MANAGER_ENABLED) {
        window.location.href = `${baseUrl}${MANAGER_NAMESPACE.slice(1)}`;
      } else {
        console.error("Failed start", error);
        setError(error);
      }
    }
  }, [tenant, baseUrl, app]);

  const value = {
    baseUrl,
    bmapi,
    businesses: user?.businesses,
    businessId: user?.business?.id,
    error,
    hideMessage,
    loading,
    message,
    notifyError,
    notifySuccess,
    pages,
    showMessage,
    starting: user === null,
    startLoading,
    stopLoading,
    tenant,
    toggleLoading,
    userData: bmapi?.userData,
    userId: user?.user_id,
  };

  return (
    <bmapiContext.Provider value={value}>{children}</bmapiContext.Provider>
  );
};
