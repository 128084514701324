import React, { useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import Routing from "./Routing";
import NotFound from "../pages/NotFound";
import Toast from "../ui/Toast";
import Loading from "../ui/Loading";
import { useBmapi } from "../utils/bmapi-context";
import {
  CONSUMER_NAMESPACE,
  MANAGER_NAMESPACE,
  OLD_MANAGER_NAMESPACE,
} from "../utils/constants";

export default function App() {
  const {
    bmapi,
    hideMessage,
    loading,
    message,
    showMessage,
    starting,
  } = useBmapi();
  const location = useLocation();

  useEffect(() => {
    const rc = new URLSearchParams(location.search).get("rc");
    if (bmapi && rc) bmapi.setTenantData({ rc });
  }, [bmapi, location]);

  return !bmapi ? null : (
    <main className={`bme-${bmapi.app}`}>
      {bmapi.customCSS && <link rel="stylesheet" href={bmapi.customCSS} />}
      <MuiThemeProvider theme={bmapi.theme.app}>
        <CssBaseline />
        {!starting && !!bmapi.app && (
          <Switch>
            <Route path={OLD_MANAGER_NAMESPACE}>
              <Redirect to={MANAGER_NAMESPACE} />
            </Route>
            {!!bmapi.isManagerEnabled && (
              <Route path={MANAGER_NAMESPACE} component={Routing} />
            )}
            {!!bmapi.isConsumerEnabled && (
              <Route path={CONSUMER_NAMESPACE} component={Routing} />
            )}
            {!bmapi.isConsumerEnabled && !!bmapi.isManagerEnabled && (
              <Route exact path={CONSUMER_NAMESPACE}>
                <Redirect to={MANAGER_NAMESPACE} />
              </Route>
            )}
            <Route component={NotFound} />
          </Switch>
        )}
        <Toast
          message={message.text}
          onClose={hideMessage}
          open={showMessage}
          variant={message.type}
        />
        <Loading loading={loading || starting} />
      </MuiThemeProvider>
    </main>
  );
}
