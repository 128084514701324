import React from "react";

import { CircularProgress, Dialog } from "@material-ui/core";

import styles from "../utils/styles";

export default function Loading({ loading }) {
  const classes = styles.useStyles();

  return (
    <Dialog open={loading} classes={{ paper: classes.loading }}>
      <CircularProgress color="secondary" />
    </Dialog>
  );
}
