import React from "react";
import { Link } from "react-router-dom";

import styles from "../utils/styles";

export default function Logo({ link, logo }) {
  const classes = styles.useStyles();
  const image = <img className={classes.headerLogo} src={logo} alt="logo" />;

  return link ? <Link to={link}>{image}</Link> : image;
}
