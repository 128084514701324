import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function Gender({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(common.gender)}
      margin="normal"
      name="gender"
      onChange={onChange}
      required={bmapi.settings.profileRequired.includes("gender")}
      select
      value={value}
    >
      <MenuItem value="">{intl.formatMessage(common.notSpecified)}</MenuItem>
      <MenuItem value="F">{intl.formatMessage(common.female)}</MenuItem>
      <MenuItem value="M">{intl.formatMessage(common.male)}</MenuItem>
    </TextField>
  );
}
