import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  List,
  Typography,
} from "@material-ui/core";
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Add as AddIcon,
  Adjust as AdjustIcon,
  Euro as EuroIcon,
  Loyalty as LoyaltyIcon,
  Person as PersonIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { useBmapi } from "../../utils/bmapi-context";
import { POSITIVE_FLOATING_NUMBER, VARIANTS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { common } from "../../messages";
import ListItemInfo from "../ListItemInfo";
import NumberInput from "../NumberInput";

export default function EarningCardActionForm({ info, owner, qrCode, rules }) {
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({ pointsToAdd: "", expense: "" });

  function handleChange(valueLabel) {
    return (event) => {
      setValues({ ...values, [valueLabel]: event.target.value });
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    startLoading();

    bmapi
      .increaseCardBalance(
        qrCode,
        bmapi.getUserInfo().business.id,
        getExpensePoints() + getExtraPoints(),
        parseInt(values.expense * 100) || 0,
        getExtraPoints()
      )
      .then(() => setSuccess(true))
      .catch((e) => setError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  function convertExpenseToPoints(expense, quantum, rate) {
    return ((parseInt(expense / quantum) * quantum) / rate) * 100;
  }

  function getExpensePoints() {
    return values.expense.match(POSITIVE_FLOATING_NUMBER) || !rules
      ? convertExpenseToPoints(
          values.expense * 100,
          rules.quantum || 1,
          rules.rate || 1
        )
      : 0;
  }

  function getExtraPoints() {
    return values.pointsToAdd.match(POSITIVE_FLOATING_NUMBER)
      ? values.pointsToAdd * 100
      : 0;
  }

  function getNewCardBalance() {
    return info.available_value + getExtraPoints() + getExpensePoints();
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <List>
            <ListItemInfo
              Icon={LoyaltyIcon}
              label={intl.formatMessage({
                id: "common.product",
                defaultMessage: "Prodotto",
              })}
              text={intl.formatMessage({
                id: "common.campaign.fidelity",
                defaultMessage: "Fidelity",
              })}
            />
            <ListItemInfo
              Icon={AdjustIcon}
              label={intl.formatMessage({
                id: "common.campaign",
                defaultMessage: "Campagna",
              })}
              text={info.campaign_name}
            />
            <ListItemInfo
              Icon={PersonIcon}
              label={intl.formatMessage({
                id: "component.actionForm.issuedTo",
                defaultMessage: "Emessa a",
              })}
              text={owner && owner.email}
            />
            <ListItemInfo
              Icon={AccountBalanceWalletIcon}
              label={intl.formatMessage({
                id: "component.actionForm.currentPointsLabel",
                defaultMessage: "Saldo disponibile",
              })}
              text={
                <Typography color="primary">
                  {intl.formatMessage(common.totalPoints, {
                    points: parseInt(info.available_value / 100),
                  })}
                </Typography>
              }
            />
            <ListItemInfo Icon={EuroIcon}>
              <NumberInput
                label={intl.formatMessage({
                  id: "component.earningCardActionForm.expense",
                  defaultMessage: "Spesa da convertire",
                })}
                fullWidth
                variant="filled"
                value={values.expense}
                required
                onChange={handleChange("expense")}
                id="expense"
                name="expense"
                autoFocus
                float
              />
            </ListItemInfo>
            <ListItemInfo Icon={AddIcon}>
              <NumberInput
                label={intl.formatMessage({
                  id: "component.earningCardActionForm.extraPoints",
                  defaultMessage: "Punti aggiuntivi",
                })}
                fullWidth
                variant="filled"
                value={values.pointsToAdd}
                name="pointsToAdd"
                id="pointsToAdd"
                onChange={handleChange("pointsToAdd")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormattedMessage
                        id="common.form.points"
                        defaultMessage="Punti"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItemInfo>
            <ListItemInfo
              Icon={TrendingUpIcon}
              label={intl.formatMessage({
                id: "component.earningCardActionForm.total",
                defaultMessage: "Saldo dopo ricarica",
              })}
              text={
                <Typography color="primary">
                  {intl.formatMessage(common.totalPoints, {
                    points: parseInt(getNewCardBalance() / 100),
                  })}
                </Typography>
              }
            />
          </List>

          <FormControl fullWidth margin="normal">
            {!success && !error ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={info.available_value === getNewCardBalance()}
              >
                <FormattedMessage
                  id="common.confirm"
                  defaultMessage="Conferma"
                />
              </Button>
            ) : (
              <Alert
                variant="filled"
                severity={success ? VARIANTS.SUCCESS : VARIANTS.ERROR}
              >
                {success ? (
                  <FormattedMessage
                    id="component.actionForm.transactionOk"
                    defaultMessage="Transazione effettuata"
                  />
                ) : (
                  error
                )}
              </Alert>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}
