import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { Box, Button, Container, Menu, MenuItem } from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  CenterFocusStrong,
} from "@material-ui/icons";

import CampaignsTable from "../../ui/CampaignsTable";
import CreateCampaignButton from "../../ui/CreateCampaignButton";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import {
  CAMPAIGN_STATUS,
  FEATURES,
  MANAGER_ROUTES,
  ROLES,
} from "../../utils/constants";

function byBusinessName(a, b) {
  return a.business_name.localeCompare(b.business_name);
}

export default function Campaigns() {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const { campaigns, loadCampaigns } = useCampaigns();
  const [currentStore, setCurrentStore] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stores, setStores] = useState(null);

  const selectStore = (id) => {
    setCurrentStore(id);
    setAnchorEl(null);
  };

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    if (campaigns) {
      setStores(
        Object.entries(
          campaigns.sort(byBusinessName).reduce(
            (acc, e) => ({
              ...acc,
              [e.business_owner_id]: e.business_owner_name,
            }),
            {}
          )
        )
      );
    }
  }, [campaigns]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  return (
    !!campaigns && (
      <Container maxWidth="sm">
        <Title>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box>
              <FormattedMessage
                id="pages.campaigns.title"
                defaultMessage="Campagne"
              />
            </Box>
            <Button
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color={currentStore ? "primary" : "inherit"}
            >
              <FormattedMessage id="common.filter" defaultMessage="Filtro" />
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted={false}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => selectStore(0)}>
                <FormattedMessage
                  id="common.allStores"
                  defaultMessage="Tutti i negozi"
                />
              </MenuItem>
              {stores?.map((option) => (
                <MenuItem
                  key={option[0]}
                  onClick={() => selectStore(option[0])}
                  selected={option[0] === currentStore}
                >
                  {option[1]}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Title>
        <CampaignsTable
          campaigns={campaigns.filter(
            (c) => !currentStore || c.business_owner_id === currentStore
          )}
          loadCampaigns={loadCampaigns}
        />
        {bmapi.can(FEATURES.VIEW_DASHBOARD) && (
          <Box my={4}>
            <Button
              component={RouterLink}
              to={MANAGER_ROUTES.HOME}
              startIcon={<ArrowBackIcon />}
            >
              <FormattedMessage
                id="common.backHome"
                defaultMessage="Torna alla home"
              />
            </Button>
          </Box>
        )}
        {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
          <CreateCampaignButton
            trigger={(onClick) => (
              <FloatingActions>
                <Action
                  icon={<AddIcon />}
                  label={intl.formatMessage({
                    id: "page.campaigns.createCampaign",
                    defaultMessage: "Crea campagna",
                  })}
                  action={onClick}
                />
              </FloatingActions>
            )}
          />
        )}
        {bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER &&
          bmapi.can(FEATURES.SCAN_QR) &&
          !bmapi.can(FEATURES.MANAGE_CAMPAIGN) &&
          campaigns.some(
            (c) => !c.toSign && c.status === CAMPAIGN_STATUS.ACTIVE
          ) && (
            <FloatingActions>
              <Action
                icon={<CenterFocusStrong />}
                label={intl.formatMessage({
                  id: "common.scan",
                  defaultMessage: "Scansiona",
                })}
                action={() => history.push(MANAGER_ROUTES.SCAN)}
              />
            </FloatingActions>
          )}
      </Container>
    )
  );
}
