import React, { useEffect, useMemo } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";

import { CONSUMER_ROUTES, TENANTS } from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";

const isHome = (page) => !!page.home;
const withPath = (page) => !!page.routing?.path;
const byPathLength = (a, b) => b.routing.path.length - a.routing.path.length;

export default function Routing() {
  const { baseUrl, bmapi, pages, userId } = useBmapi();
  const location = useLocation();
  const history = useHistory();
  const cbUrl = bmapi.getTenantData().signinCallbackUrl;

  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  useEffect(() => {
    const cbUrl = bmapi.getTenantData().signinCallbackUrl;
    if (userId && cbUrl) {
      history.push(cbUrl.replace(baseUrl, "/"));
      bmapi.setCallbackUrl(false);
    }
  }, [bmapi, userId, baseUrl, history]);

  const activePages = useMemo(() => pages.filter(withPath), [pages]);

  return (
    (!userId || !cbUrl) && (
      <Switch>
        {activePages.sort(byPathLength).map((page) => (
          <page.routing.Wrapper
            component={page.routing.Component}
            exact={page.routing.exact}
            key={page.routing.path}
            path={page.routing.path}
          />
        ))}

        {bmapi.tenant === TENANTS.LUCCA && (
          <Redirect
            from={CONSUMER_ROUTES.LUCCA_CANCEL}
            to={CONSUMER_ROUTES.LUCCA_BUY}
          />
        )}

        <Redirect to={activePages.find(isHome).routing?.path} />
      </Switch>
    )
  );
}
