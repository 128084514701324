import { defineMessages } from "react-intl";

export const common = defineMessages({
  all: {
    id: "common.all",
    defaultMessage: "Tutti",
  },
  automatic: {
    id: "common.form.automatic",
    defaultMessage: "Automatica",
  },
  back: {
    id: "common.back",
    defaultMessage: "Indietro",
  },
  backHome: {
    id: "common.backHome",
    defaultMessage: "Torna alla home",
  },
  birthday: {
    id: "common.form.birthday",
    defaultMessage: "Data di nascita",
  },
  campaign: {
    id: "common.campaign",
    defaultMessage: "Campagna",
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Annulla",
  },
  close: {
    id: "common.close",
    defaultMessage: "Chiudi",
  },
  confirm: {
    id: "common.confirm",
    defaultMessage: "Conferma",
  },
  create: {
    id: "common.create",
    defaultMessage: "Crea",
  },
  day: {
    id: "common.day",
    defaultMessage: "Giorno",
  },
  days: {
    id: "common.form.days",
    defaultMessage: "Giorni",
  },
  delete: {
    id: "common.delete",
    defaultMessage: "Elimina",
  },
  demo: {
    id: "common.demo",
    defaultMessage: "Demo",
  },
  download: {
    id: "common.download",
    defaultMessage: "Download",
  },
  email: {
    id: "common.email",
    defaultMessage: "Email",
  },
  emailVerified: {
    id: "common.emailVerified",
    defaultMessage: "Email verificata con successo",
  },
  eventRunning: {
    id: "common.eventRunning",
    defaultMessage: "Evento in corso",
  },
  noEventRunning: {
    id: "common.noEventRunning",
    defaultMessage: "Nessun evento in corso",
  },
  endEvent: {
    id: "common.endEvent",
    defaultMessage: "Concludi evento",
  },
  eventCode: {
    id: "common.eventCode",
    defaultMessage: "Codice evento",
  },
  startEvent: {
    id: "common.startEvent",
    defaultMessage: "Avvia evento",
  },
  notPresent: {
    id: "common.notPresent",
    defaultMessage: "Assente",
  },
  female: {
    id: "common.form.female",
    defaultMessage: "Femmina",
  },
  fiscalCode: {
    id: "common.form.fiscal_code",
    defaultMessage: "Codice fiscale",
  },
  from: {
    id: "common.from",
    defaultMessage: "Da",
  },
  gender: {
    id: "common.form.gender",
    defaultMessage: "Genere",
  },
  language: {
    id: "common.form.language",
    defaultMessage: "Lingua",
  },
  lastName: {
    id: "common.form.last_name",
    defaultMessage: "Cognome",
  },
  locations: {
    id: "common.locations",
    defaultMessage: "Località",
  },
  male: {
    id: "common.form.male",
    defaultMessage: "Maschio",
  },
  mobile: {
    id: "common.mobile",
    defaultMessage: "Telefono",
  },
  month: {
    id: "common.month",
    defaultMessage: "Mese",
  },
  months: {
    id: "common.months",
    defaultMessage: "Mesi",
  },
  name: {
    id: "common.name",
    defaultMessage: "Nome",
  },
  notSpecified: {
    id: "common.notSpecified",
    defaultMessage: "Non specificato",
  },
  ok: {
    id: "common.ok",
    defaultMessage: "Ok",
  },
  password: {
    id: "common.password",
    defaultMessage: "Password",
  },
  passwordUpdated: {
    id: "common.passwordUpdated",
    defaultMessage: "Password cambiata con successo",
  },
  prizes: {
    id: "common.prizes",
    defaultMessage: "Premi",
  },
  product: {
    id: "common.product",
    defaultMessage: "Prodotto",
  },
  quantity: {
    id: "common.quantity",
    defaultMessage: "Quantità",
  },
  role: {
    id: "common.role",
    defaultMessage: "Ruolo",
  },
  save: {
    id: "common.save",
    defaultMessage: "Salva",
  },
  send: {
    id: "common.send",
    defaultMessage: "Invia",
  },
  statistics: {
    id: "common.statistics",
    defaultMessage: "Statistiche",
  },
  store: {
    id: "common.store",
    defaultMessage: "Negozio",
  },
  stores: {
    id: "common.stores",
    defaultMessage: "Negozi",
  },
  times: {
    id: "common.form.times",
    defaultMessage: "Volte",
  },
  to: {
    id: "common.to",
    defaultMessage: "A",
  },
  totalPoints: {
    id: "common.totalPoints",
    defaultMessage: "{points, plural, one {# punto} other {# punti}}",
  },
  transactions: {
    id: "common.transactions",
    defaultMessage: "Transazioni",
  },
  value: {
    id: "common.value",
    defaultMessage: "Valore",
  },
  week: {
    id: "common.week",
    defaultMessage: "Settimana",
  },
  welcome: {
    id: "common.welcome",
    defaultMessage: "Benvenuto!",
  },
  welcomeMsg: {
    id: "common.welcomeMsg",
    defaultMessage:
      "Utilizza <link>il tuo QR Code</link> per ottenere nuovi card e coupon. Lo puoi trovare aprendo il menu in alto a destra o usando il pulsante in fondo a questa pagina.",
  },
});
