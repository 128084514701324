import { errors } from "../messages";

export function getErrorMessage(error) {
  if (typeof error === "string") return error;

  const errorCodeMap = {
    "auth/email-already-in-use": errors.emailAlreadyInUse,
    "auth/user-not-found": errors.wrongUser,
    "auth/wrong-password": errors.wrongUser,
    EXPIRED_PERMISSION: errors.expiredQrCode,
    DEMO_LIMITS_ERROR: errors.demoLimitReached,
    ORDERS_LIMIT_EXCEEDED: errors.ordersLimitExceeded,
    MAX_PRODUCTS_PER_USER_REACHED: errors.maxProducts,
    CAMPAIGN_MAX_QTY_EXCEDED: errors.maxQtyExcedeed,
    PRIMARY_PRODUCT_IS_MANDATORY: errors.primaryMandatory,
    EXTERNAL_CODES_SOLD_OUT: errors.codesSoldOut,
  };

  const errorMessageMap = {
    USER_NOT_VERIFIED: errors.userNotVerified,
    VERIFICATION_TIME_EXPIRED: errors.userNotVerified,
    "ExpirationDate not valid: expiration must be at least one day in the future":
      errors.campaignExpirationPast,
    "Token not authorized for the data's tenantID": errors.tokenNotAuthorized,
    "Cannot delete campaign. Campaign has products associated and is not a demo":
      errors.campaignNotDeletable,
    "VALIDATION_ERROR: Product is expired": errors.expiredProduct,
    "VALIDATION_ERROR: Product is not ACTIVE": errors.usedProduct,
    "UseTerms not signed": errors.notSigned,
    "datastore: no such entity": errors.invalidCode,
    TENANT_NOT_AUTHORIZED: errors.notAuthorized,
    USER_NOT_FOUND: errors.userNotFound,
  };

  if (errorCodeMap[error.code]) return errorCodeMap[error.code];
  if (errorMessageMap[error.message]) return errorMessageMap[error.message];

  return error.message;
}

export function getErrorMessageString(error, intl) {
  const errorMessage = getErrorMessage(error);
  return typeof errorMessage === "string"
    ? errorMessage
    : intl.formatMessage(errorMessage);
}
