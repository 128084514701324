import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";
import { FEATURES } from "../../../utils/constants";

export default function LuccaStats() {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [businesses, setBusinesses] = useState([]);
  const [totals, setTotals] = useState({});
  const [totalsStats, setTotalsStats] = useState({});
  const [statsPass, setStatsPass] = useState({});
  const [statsBag, setStatsBag] = useState({});
  const [inventory, setInventory] = useState(true);

  useEffect(() => {
    startLoading();
    Promise.all([bmapi.getTenantBusiness(), bmapi.getInventories()]).then(
      ([bs, inv]) => {
        setBusinesses(
          bs
            .filter(
              (b) =>
                bmapi.can(FEATURES.VIEW_REPORTS) ||
                b.id === bmapi.getUserInfo().business.id
            )
            .sort(
              (a, b) =>
                a.region.localeCompare(b.region) || a.name.localeCompare(b.name)
            )
            .map((b) => ({
              ...b,
              availabilityBase: inv?.find(
                (i) =>
                  i.b_id === b.id &&
                  i.order_settings_id === bmapi.settings.orderSettingsIDBase
              ),
              availabilityGoody: inv?.find(
                (i) =>
                  i.b_id === b.id &&
                  i.order_settings_id === bmapi.settings.orderSettingsIDGoody
              ),
            }))
        );
      }
    );
  }, [bmapi, startLoading, stopLoading]);

  useEffect(() => {
    setTotals(
      businesses.reduce(
        (acc, bs) => ({
          availableBase:
            (acc.availableBase || 0) + bs.availabilityBase?.available || 0,
          totalBase: (acc.totalBase || 0) + bs.availabilityBase?.total || 0,
          availableGoody:
            (acc.availableGoody || 0) + bs.availabilityGoody?.available || 0,
          totalGoody: (acc.totalGoody || 0) + bs.availabilityGoody?.total || 0,
        }),
        {}
      )
    );
  }, [businesses]);

  useEffect(() => {
    if (businesses.length) {
      const load = (campaignId) =>
        Promise.all(
          businesses.map((b) => bmapi.getCampaignDetails(campaignId, b.id))
        ).then((stats) =>
          stats.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.performance.business_id]: cur.performance,
            }),
            {}
          )
        );

      Promise.all([
        load(bmapi.settings.luccaPassId),
        load(bmapi.settings.luccaBagId),
      ])
        .then(([passes, bags]) => {
          setStatsPass(passes);
          setStatsBag(bags);
          setTotalsStats(
            businesses.reduce(
              (acc, bs) => ({
                usedPass: (acc.usedPass || 0) + passes[bs.id].used_qty || 0,
                availablePass:
                  (acc.availablePass || 0) +
                    (bs.availabilityBase?.total +
                      bs.availabilityGoody?.total -
                      bs.availabilityBase?.available -
                      bs.availabilityGoody?.available) || 0,
                usedBags: (acc.usedBags || 0) + bags[bs.id].used_qty || 0,
                availableBags:
                  (acc.availableBags || 0) +
                    (bs.availabilityGoody?.total -
                      bs.availabilityGoody?.available) || 0,
              }),
              {}
            )
          );
        })
        .finally(stopLoading);
    }
  }, [businesses, bmapi, stopLoading]);

  return (
    <Container maxWidth="md">
      <Box mb={3}>
        <Grid
          component="label"
          container
          alignItems="center"
          spacing={1}
          justify="flex-end"
        >
          <Grid item>Inventario</Grid>
          <Grid item>
            <Switch
              checked={!inventory}
              onChange={(_, f) => setInventory(!f)}
            />
          </Grid>
          <Grid item>Utilizzi</Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Regione</TableCell>
              <TableCell>Negozio</TableCell>
              {inventory ? (
                <React.Fragment>
                  <TableCell colSpan={3} align="center">
                    Disponibilità pass
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    Disponibilità pass + bag
                  </TableCell>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableCell colSpan={3} align="center">
                    Pass
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    Bags
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>

              {inventory ? (
                <React.Fragment>
                  <TableCell align="right">
                    <Typography variant="caption">totale</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">venduti</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      <strong>residui</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">totale</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">venduti</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      <strong>residui</strong>
                    </Typography>
                  </TableCell>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableCell align="right">
                    <Typography variant="caption">disponibili</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">venduti</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      <strong>usati</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">disponibili</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">venduti</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="caption">
                      <strong>usati</strong>
                    </Typography>
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {businesses.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.region}</TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {inventory ? (
                  <React.Fragment>
                    <TableCell align="right">
                      {row.availabilityBase?.total}
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityBase?.total -
                        row.availabilityBase?.available}
                    </TableCell>
                    <TableCell align="right">
                      <strong>{row.availabilityBase?.available}</strong>
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityGoody?.total}
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityGoody?.total -
                        row.availabilityGoody?.available}
                    </TableCell>
                    <TableCell align="right">
                      <strong>{row.availabilityGoody?.available}</strong>
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <TableCell align="right">
                      {row.availabilityBase?.total +
                        row.availabilityGoody?.total}
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityBase?.total +
                        row.availabilityGoody?.total -
                        row.availabilityBase?.available -
                        row.availabilityGoody?.available}
                    </TableCell>
                    <TableCell align="right">
                      <strong>{statsPass[row.id].used_qty}</strong>
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityGoody?.total}
                    </TableCell>
                    <TableCell align="right">
                      {row.availabilityGoody?.total -
                        row.availabilityGoody?.available}
                    </TableCell>
                    <TableCell align="right">
                      <strong>{statsBag[row.id].used_qty}</strong>
                    </TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell component="th" scope="row">
                <strong>Totali</strong>
              </TableCell>
              {inventory ? (
                <React.Fragment>
                  <TableCell align="right">
                    <strong>{totals.totalBase}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {totals.totalBase
                        ? totals.totalBase - totals.availableBase
                        : ""}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.availableBase}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.totalGoody}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>
                      {totals.totalGoody
                        ? totals.totalGoody - totals.availableGoody
                        : ""}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.availableGoody}</strong>
                  </TableCell>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableCell align="right">
                    <strong>{totals.totalBase + totals.totalGoody}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalsStats.availablePass}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalsStats.usedPass}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totals.totalGoody}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalsStats.availableBags}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{totalsStats.usedBags}</strong>
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
