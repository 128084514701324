import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import SignInForm from "../../ui/SignInForm";
import { useBmapi } from "../../utils/bmapi-context";
import { ACTIONS, CONSUMER_ROUTES, ERRORS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function SignIn() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const { action } = useParams();

  function onSubmit(email, password) {
    startLoading();

    return bmapi.doSignInWithEmailAndPassword(email, password).catch((e) => {
      stopLoading();
      notifyError(getErrorMessageString(e, intl));
    });
  }

  useEffect(() => {
    if (action === ACTIONS.LOGGED && bmapi.settings.customLogin) {
      startLoading();
      bmapi.doSignInWithCustomToken().catch((e) => {
        if (e.code === ERRORS.COOKIES_NOT_FOUND) {
          window.location.href = bmapi.settings.customLogin.replace(
            "{cbUrl}",
            `${bmapi.baseUrl}${CONSUMER_ROUTES.SIGN_IN_ACTION.replace(
              ":action?",
              ACTIONS.LOGGED
            ).slice(1)}`
          );
        } else {
          notifyError(getErrorMessageString(e, intl));
        }
      });
    }
  }, [action, bmapi, intl, notifyError, startLoading]);

  return !action && <SignInForm onSubmit={onSubmit} routes={CONSUMER_ROUTES} />;
}
