import { createMuiTheme } from "@material-ui/core/styles";
import { CONSUMER } from "./constants";

const commonTheme = (theme) => ({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".mdl-button.mdl-button": {
          borderRadius: theme.borderRadius,
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    },
  },
  props: { bigAvatarSize: theme.bigAvatarSize },
  shape: { borderRadius: theme.borderRadius },
  typography: { fontFamily: theme.fontFamily },
});

const consumerAppTheme = (theme) =>
  createMuiTheme({
    ...commonTheme(theme),
    palette: {
      primary: { main: theme.primary, contrastText: theme.primaryContrast },
      secondary: { main: theme.secondary },
      common: {
        bg: theme.background || theme.primary,
        contrastText: theme.primaryContrast,
      },
    },
  });

const consumerLoginTheme = (theme) =>
  createMuiTheme({
    ...commonTheme(theme),
    palette: {
      primary: { main: theme.primary, contrastText: theme.primaryContrast },
      secondary: { main: theme.secondary },
      background: { default: theme.background || theme.primary },
      common: {
        bg: theme.background || theme.primary,
        contrastText: theme.primaryContrast,
      },
    },
  });

const managerAppTheme = (theme) =>
  createMuiTheme({
    ...commonTheme(theme),
    palette: {
      primary: { main: theme.primary, contrastText: theme.primaryContrast },
      secondary: { main: theme.secondary },
      common: { bg: theme.background || theme.secondary },
    },
  });

const managerLoginTheme = (theme) =>
  createMuiTheme({
    ...commonTheme(theme),
    palette: {
      primary: { main: theme.primary, contrastText: theme.primaryContrast },
      secondary: { main: theme.secondary },
      background: { default: theme.background || theme.secondary },
      common: { bg: theme.background || theme.secondary },
    },
  });

export default function createTheme(theme, app) {
  return app === CONSUMER
    ? {
        app: consumerAppTheme(theme),
        login: consumerLoginTheme(theme),
      }
    : {
        app: managerAppTheme(theme),
        login: managerLoginTheme(theme),
      };
}
