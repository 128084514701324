import { defineMessages } from "react-intl";

export const errors = defineMessages({
  campaignExpirationPast: {
    id: "errors.campaignExpirationPast",
    defaultMessage: "La campagna non puo' terminare la distribuzione oggi",
  },
  campaignNotDeletable: {
    id: "errors.campaignNotDeletable",
    defaultMessage:
      "Impossibile eliminare la campagna. Sono già stati emessi dei prodotti",
  },
  demoLimitReached: {
    id: "errors.demoLimitReached",
    defaultMessage: "Campagna demo: numero massimo di prodotti già raggiunto",
  },
  emailAlreadyInUse: {
    id: "errors.emailAlreadyInUse",
    defaultMessage: "L'indirizzo email inserito risulta già registrato",
  },
  expiredProduct: {
    id: "errors.expiredProduct",
    defaultMessage: "Il prodotto scansionato risulta scaduto",
  },
  expiredQrCode: {
    id: "errors.expiredQrCode",
    defaultMessage:
      "Il QR Code mostrato risulta scaduto. Chiedere all'utente di aggiornare il QR Code e riprovare la scansione",
  },
  invalidCode: {
    id: "errors.invalidCode",
    defaultMessage: "Il codice non appartiene ad un prodotto valido",
  },
  maxProducts: {
    id: "errors.maxProducts",
    defaultMessage:
      "L'utente ha già raggiunto il limite massimo di prodotti per questa campagna",
  },
  notAuthorized: {
    id: "errors.notAuthorized",
    defaultMessage:
      "Il codice non appartiene a un prodotto gestito dallo user autenticato",
  },
  ordersLimitExceeded: {
    id: "errors.ordersLimitExceeded",
    defaultMessage:
      "Hai già acquistato questo prodotto e non puoi acquistarlo di nuovo",
  },
  tokenNotAuthorized: {
    id: "errors.tokenNotAuthorized",
    defaultMessage:
      "Non è possibile eseguire l'operazione richiesta: non si possiedono i permessi necessari",
  },
  notSigned: {
    id: "errors.notSigned",
    defaultMessage:
      "Per utilizzare il prodotto scansionato devi prima aderire alla campagna",
  },
  usedProduct: {
    id: "errors.usedProduct",
    defaultMessage: "ll prodotto risulta già utilizzato",
  },
  userNotFound: {
    id: "errors.userNotFound",
    defaultMessage: "La mail inserita non appartiene ad un utente registrato",
  },
  userNotVerified: {
    id: "errors.userNotVerified",
    defaultMessage: "Prima di accedere devi certificare la tua mail",
  },
  wrongUser: {
    id: "errors.wrongUser",
    defaultMessage: "Email o password errati",
  },
  maxQtyExcedeed: {
    id: "errors.maxQtyExcedeed",
    defaultMessage: "Prodotti esauriti",
  },
  primaryMandatory: {
    id: "errors.primaryMandatory",
    defaultMessage:
      "Per ottenere questo prodotto è necessario aderire all'evento principale",
  },
  codesSoldOut: {
    id: "errors.codesSoldOut",
    defaultMessage: "Prodotti esauriti",
  },
});
