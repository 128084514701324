import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Container, Tab, Tabs } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import ManageLocations from "../../components/ManageLocations";
import ManageStores from "../../components/ManageStores";
import { common } from "../../messages";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { FEATURES, MANAGER_ROUTES } from "../../utils/constants";

export default function Manage() {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const [section, setSection] = useState(false);
  const [tabs, setTabs] = useState(false);

  useEffect(() => {
    const TABS = [
      {
        Component: ManageStores,
        key: "STORES",
        label: intl.formatMessage(common.stores),
      },
      {
        Component: ManageLocations,
        feature: FEATURES.LOCATIONS,
        key: "LOCATIONS",
        label: intl.formatMessage(common.locations),
      },
    ].filter((t) => !t.feature || bmapi.can(t.feature));

    setTabs(TABS);
    setSection(TABS[0]);
  }, [bmapi, intl]);

  const changeTab = (_, sec) => setSection(tabs.find((t) => t.key === sec));

  return (
    <Container maxWidth="sm">
      <Title>
        <FormattedMessage
          id="pages.manage.tenantSettings"
          defaultMessage="Tenant settings"
        />
      </Title>

      {tabs && (
        <Box mb={2}>
          <Tabs
            value={section.key}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={changeTab}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.key}
                label={tab.label}
                value={tab.key}
                style={{ minWidth: 0 }}
              />
            ))}
          </Tabs>
        </Box>
      )}

      {section && <section.Component />}

      <Box my={4}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBackIcon />}
        >
          <FormattedMessage
            id="common.backHome"
            defaultMessage="Torna alla home"
          />
        </Button>
      </Box>
    </Container>
  );
}
