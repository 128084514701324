const appDictionaries = {
  de: require("../locales/de.json"),
  en: require("../locales/en.json"),
  es: require("../locales/es.json"),
  fr: require("../locales/fr.json"),
  it: require("../locales/it.json"),
};

const datesDictionaries = {
  de: require("date-fns/locale/de").default,
  en: require("date-fns/locale/en-US").default,
  es: require("date-fns/locale/es").default,
  fr: require("date-fns/locale/fr").default,
  it: require("date-fns/locale/it").default,
};

export const langs = [
  { label: "Deutsch", value: "de" },
  { label: "Español", value: "es" },
  { label: "Français", value: "fr" },
  { label: "English", value: "en" },
  { label: "Italiano", value: "it" },
];

export function getLanguageWithoutRegionCode(language) {
  return language.toLowerCase().split(/[_-]+/)[0];
}

function getMessages(dictionary, language, fallback) {
  return (
    dictionary[getLanguageWithoutRegionCode(language)] ||
    dictionary[language] ||
    dictionary[fallback]
  );
}

function loadSkin(skin, language, folder = "skins") {
  try {
    const conf = require(`../locales/${folder}/${skin}.${language}.json`);
    return conf;
  } catch {
    return {};
  }
}

function loadTenantSkin(tenant, language) {
  return loadSkin(tenant, language, "tenants");
}

export function getAppMessages(language, fallback, skin, tenant) {
  const lang = getLanguageWithoutRegionCode(language);
  return {
    ...getMessages(appDictionaries, language, fallback),
    ...(skin ? loadSkin(skin, lang) : {}),
    ...(tenant ? loadTenantSkin(tenant, lang) : {}),
  };
}

export function getDateMessages(language, fallback) {
  return getMessages(datesDictionaries, language, fallback);
}
