import React, { useEffect } from "react";
import { useIntl } from "react-intl";

import { Container, Typography } from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";

export default function Home() {
  const intl = useIntl();
  const { stopLoading } = useBmapi();

  useEffect(() => {
    stopLoading();
  }, [stopLoading]);

  return (
    <Container maxWidth="md">
      <Typography variant="body1" gutterBottom>
        {intl.formatMessage({
          id: "pages.dashboard.emptyMessage",
          value: "Non ci sono negozi associati al tuo indirizzo email.",
        })}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {intl.formatMessage({
          id: "pages.dashboard.emptySolution",
          value: "Contatta l'amministratore per maggiori informazioni.",
        })}
      </Typography>
    </Container>
  );
}
