export const MULTIPLE_TENANTS = JSON.parse(
  process.env.REACT_APP_MULTIPLE_TENANTS
);

export const ENVS = {
  PRODUCTION: "production",
};

export const TENANTS = {
  CSP: "csp",
  DEMO: "demo",
  EKOM: "ekom",
  LUCCA: "lcng",
};

export const DEFAULT_TENANT_MAP = {
  "campfire.demo3.mediaus.online": TENANTS.LUCCA,
};

export const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
export const LOAD_DEFAULT_TENANT = process.env.REACT_APP_LOAD_DEFAULT_TENANT;

export const DOMAIN_WILDCARD = "@liberacta.";
export const API_ERROR = "ApiError";

export const DEFAULT_MANIFEST = {
  background_color: "#fafafa",
  display: "standalone",
  icons: [
    {
      src: "/static/tenants/demo/icon.png",
      sizes: "500x500",
      type: "image/png",
    },
  ],
  name: `BMarkEn by Liberacta`,
  short_name: "BMarkEn",
  theme_color: "#e60",
};

export const POSITIVE_FLOATING_NUMBER = /^(?:\d+|0)+(?:\.\d{0,2})?$/gm;
export const UUID_LENGTH = 36;
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const UUID_REGEX_PREFIX = /(.*)([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;

export const EVENT_PREFIX = "EVT@";
export const LOCATION_PREFIX = "LOC@";
export const USER_PREFIX = "UID@";

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const LINK_TYPE = {
  ACTION: "action",
  PAGE: "page",
};

export const ACTIONS = {
  LOGGED: "logged",
};

export const FIREBASE_ACTIONS = {
  RECOVER_EMAIL: "recoverEmail",
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};

export const CAMPAIGN_ACTION = {
  CLONE: "clone",
  DELETE: "delete",
  EDIT: "edit",
  ISSUE: "issue",
  JOIN: "join",
  LINK: "link",
  MOREINFO: "moreInfo",
  SHARE: "share",
  START: "start",
  STOP: "stop",
  WAITINGLIST: "waitingList",
};

export const ERRORS = {
  APP_NOT_CONFIGURED: "APP_NOT_CONFIGURED",
  CLIENTID_NOT_FOUND: "CLIENTID_NOT_FOUND",
  COOKIES_NOT_FOUND: "COOKIES_NOT_FOUND",
  NOT_FOUND: "NOT_FOUND",
  ONLY_MANAGER_ENABLED: "ONLY_MANAGER_ENABLED",
  TOKEN_NOT_VALID: "TOKEN_NOT_VALID",
  USER_NOT_VERIFIED: "USER_NOT_VERIFIED",
  VERIFICATION_TIME_EXPIRED: "VERIFICATION_TIME_EXPIRED",
};

export const EMPTY_USER = {
  language: "",
  user_id: "",
  user_name: "",
  role: "",
  business: false,
  token_info: {},
  firebase_token_id: "",
};

export const ROLES = {
  USER: "U",
  STORE_MANAGER: "M",
  TENANT_MANAGER: "TM",
};

export const USER_STATUS = {
  ANONYMOUS: "anonymous",
  ACTIVATION: "activation",
  LOGGED: "logged",
};

export const USER_ROLES = {
  ASSISTANT: 27,
  BUSINESS_MANAGER: 63,
  CASHIER: 7,
  EDITOR: 19,
  REPORTER: 67,
};

export const PERMISSIONS = {
  ISSUE_BUSINESS_PRODUCTS: "IssueBusinessProducts",
  MANAGE_BUSINESS_CAMPAIGN: "ManageBusinessCampaign",
  MANAGE_SUBSCRIPTION: "ManageSubscription",
  USE_BUSINESS_PRODUCTS: "UseBusinessProducts",
  VIEW_BUSINESS_CAMPAIGN: "ViewBusinessCampaign",
  VIEW_BUSINESS_STATISTICS: "ViewBusinessStatistics",
  VIEW_CAMPAIGN_STATISTICS: "ViewCampaignStatistics",
  VIEW_REPORTS: "ViewBusinessReports",
};

export const FEATURES = {
  ACCOUNT: "account",
  ACCOUNT_EXTRA_INFO: "accountExtraInfo",
  CALENDAR: "calendar",
  CAMPAIGN_COUPON: "campaignCoupon",
  CAMPAIGN_EARNING_CARD: "campaignEarningCard",
  CAMPAIGN_EVENT_PASS: "campaignEventPass",
  CAMPAIGN_SHOPPING_CARD: "campaignShoppingCard",
  CAMPAIGN_PROVISIONING_CARD: "campaignProvisioningCard",
  CHECKIN: "checkin",
  DELETE_ACCOUNT: "deleteAccount",
  EXPORT: "export",
  ISSUE_PRODUCT: "issueProduct",
  LOCATIONS: "locations",
  LOGOUT: "logout",
  MANAGE_CAMPAIGN: "createCampaigns",
  MANAGE_SETTINGS: "manageSetting",
  MY_CODE: "myQrCode",
  SCAN_QR: "scanQrCode",
  SEND_POINTS: "sendPoints",
  VIEW_CAMPAIGNS: "viewCampaigns",
  VIEW_DASHBOARD: "viewDashboard",
  VIEW_REPORTS: "viewReports",
};

export const EXPERIMENTAL_FEATURES = [FEATURES.CHECKIN, FEATURES.LOCATIONS];

export const FEATURE_PERMISSION = {
  [FEATURES.EXPORT]: PERMISSIONS.VIEW_BUSINESS_STATISTICS,
  [FEATURES.ISSUE_PRODUCT]: PERMISSIONS.ISSUE_BUSINESS_PRODUCTS,
  [FEATURES.MANAGE_CAMPAIGN]: PERMISSIONS.MANAGE_BUSINESS_CAMPAIGN,
  [FEATURES.SCAN_QR]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.SEND_POINTS]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.VIEW_CAMPAIGNS]: PERMISSIONS.VIEW_BUSINESS_CAMPAIGN,
  [FEATURES.VIEW_DASHBOARD]: PERMISSIONS.VIEW_BUSINESS_STATISTICS,
  [FEATURES.VIEW_REPORTS]: PERMISSIONS.VIEW_REPORTS,
};

export const BUSINESS_TYPES = {
  MERCHANT: "Merchant",
  LOOP: "Loop",
};

export const CODE_FORMATS = {
  STRING: "string",
  QR_CODE: "qrcode",
};

export const PRODUCT_TYPES = {
  CAMPAIGN_COUPON: "CAMPAIGN_COUPON",
  CAMPAIGN_EARNING_CARD: "CAMPAIGN_EARNING_CARD",
  CAMPAIGN_EVENT_PASS: "CAMPAIGN_EVENT_PASS",
  CAMPAIGN_SHOPPING_CARD: "CAMPAIGN_SHOPPING_CARD",
  // CAMPAIGN_AMAZON: "CAMPAIGN_AMAZON",
  // CAMPAIGN_MULTIWIN: "CAMPAIGN_MULTIWIN",
};

export const CAMPAIGNS_LABELS = {
  CARD: "card",
  COUPON: "coupon",
  PASS: "pass",
  SUBSCRIPTION: "subscription",
};

export const PRODUCT_SUBTYPES = {
  COUPON_DISCOUNT: "DISCOUNT_COUPON",
  COUPON_SIMPLE: "SIMPLE_COUPON",
  COUPON_VALUE: "VALUE_COUPON",
  EARNING_CARD_SIMPLE: "EARNING_CARD_SIMPLE",
  EVENT_PASS_SIMPLE: "EVENT_PASS_SIMPLE",
  PROVISIONING_CARD: "SHOPPING_CARD_PROVISIONING",
  SHOPPING_CARD_SIMPLE: "SHOPPING_CARD_SIMPLE",
  // COUPON_AMAZON: "AMAZON_COUPON",
  // COUPON_MULTIWIN: "MULTIWIN_COUPON",
  // SHOPPING_CARD_RECHARGEABLE: "SHOPPING_CARD_RECHARGEABLE",
};

export const PRODUCTS = {
  COUPON_DISCOUNT_PERC: "COUPON_DISCOUNT_PERC",
  COUPON_DISCOUNT_VALUE: "COUPON_DISCOUNT_VALUE",
  COUPON_SIMPLE: "COUPON_SIMPLE",
  COUPON_MULTISHOT: "COUPON_MULTISHOT",
  EARNING_CARD: "EARNING_CARD",
  EVENT_PASS: "EVENT_PASS",
  PROVISIONING_CARD: "PROVISIONING_CARD",
  SHOPPING_CARD: "SHOPPING_CARD",
};

export const TX_TYPES = {
  BURN: "TX_TYPE_BURN",
  DECREASE: "TX_TYPE_DECREASE",
  EXPIRE: "TX_TYPE_EXPIRE",
  INCREASE: "TX_TYPE_INCREASE",
  ISSUE: "TX_TYPE_ISSUE",
};

export const TX_DESCRIPTIONS = {
  PRIZE_ISSUE: "Prize issue",
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
  SUSPENDED: 4,
};

export const VARIANTS = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};

export const CONSUMER = "consumer";
export const MANAGER = "manager";
export const CONSUMER_NAMESPACE = "/";
export const OLD_MANAGER_NAMESPACE = "/merchant/";
export const MANAGER_NAMESPACE = "/manager/";

export const ROUTES = {
  ACCOUNT: "account/",
  ACTION: "action/",
  HOME: "",
  PASSWORD_FORGET: "pw-forget/",
  SIGN_IN: "signin/",
  SIGN_IN_ACTION: "signin/:action?/",
  SIGN_UP: "signup/",
  STATIC: "content/:content?/",
};

export const CONSUMER_ROUTES = {
  ACCOUNT: `${CONSUMER_NAMESPACE}${ROUTES.ACCOUNT}`,
  ACTION: `${CONSUMER_NAMESPACE}${ROUTES.ACTION}`,
  CAMPAIGN: `${CONSUMER_NAMESPACE}campaign/:campaignId/`,
  CALENDAR: `${CONSUMER_NAMESPACE}campaign/:campaignId/calendar/`,
  HOME: `${CONSUMER_NAMESPACE}`,
  PASSWORD_FORGET: `${CONSUMER_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  QR_CODE: `${CONSUMER_NAMESPACE}code/`,
  SCAN: `${CONSUMER_NAMESPACE}scan/`,
  SIGN_IN: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_IN_ACTION: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_UP: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_UP}`,
  STATIC: `${CONSUMER_NAMESPACE}${ROUTES.STATIC}`,

  LUCCA_BUY: `${CONSUMER_NAMESPACE}get_pass/`,
  LUCCA_CONFIRM: `${CONSUMER_NAMESPACE}confirm_pass/`,
  LUCCA_CANCEL: `${CONSUMER_NAMESPACE}cancel_order/`,
};

export const MANAGER_ROUTES = {
  ACCOUNT: `${MANAGER_NAMESPACE}${ROUTES.ACCOUNT}`,
  CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/`,
  CAMPAIGNS: `${MANAGER_NAMESPACE}campaigns/:filter?/`,
  CLONE_CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/clone/`,
  CREATE_CAMPAIGN: `${MANAGER_NAMESPACE}campaigns/create/:subtype?/`,
  DASHBOARD: `${MANAGER_NAMESPACE}dashboard/`,
  EDIT_CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/edit/`,
  EXPORT: `${MANAGER_NAMESPACE}export/`,
  HOME: `${MANAGER_NAMESPACE}`,
  MANAGE: `${MANAGER_NAMESPACE}settings/`,
  PASSWORD_FORGET: `${MANAGER_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  PROMOS: `${MANAGER_NAMESPACE}promo/`,
  RESERVATIONS: `${MANAGER_NAMESPACE}campaign/:campaignId/reservations/`,
  SCAN: `${MANAGER_NAMESPACE}scan/`,
  SEND_POINTS: `${MANAGER_NAMESPACE}send-points/`,
  SIGN_IN_ACTION: `${MANAGER_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_IN: `${MANAGER_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_UP: `${MANAGER_NAMESPACE}${ROUTES.SIGN_UP}`,
  STATIC: `${MANAGER_NAMESPACE}${ROUTES.STATIC}`,
  STORES: `${MANAGER_NAMESPACE}stores/`,
  TENANT_SETTINGS: `${MANAGER_NAMESPACE}configuration/`,

  LUCCA_STATS: `${MANAGER_NAMESPACE}lucca-stats/`,
};

export const API = {
  BUSINESS: "/business/{business}",
  CREATE_BUSINESS: "/business",
  DELETE_CONTENT: "/business/{business}/contents/{content}",
  GET_BUSINESS_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CAMPAIGN_PERF: "/business/{business}/campaigns/{campaign}/performance",
  GET_CAMPAIGN_DETAILS: "/business/{business}/campaigns/{campaign}/details",
  GET_CAMPAIGN_UPLOAD: "/business/{business}/campaigns/{campaign}/upload-url",
  GET_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CONTENTS: "/business/{business}/contents",
  GET_PRODUCT_INFO: "/business/{business}/products/{qr-code}/info",
  GET_TERMS: "/business/{business}/terms",
  SIGN_TERM: "/business/{business}/terms/{terms}/sign",
  GET_RULES: "/business/{business}/campaigns/{campaign}/rules",
  GET_UPLOAD_CONTENTS: "/business/{business}/contents/upload-url",
  GET_USE_PERMISSION_BY_EMAIL: "/business/{business}/products/default/qrcode",
  UPDATE_CONTENT_STATUS: "/business/{business}/contents/{content}/{status}",
  UPDATE_CONTENT: "/business/{business}/contents/{content}",

  CAMPAIGN_CREATE: "/campaigns",
  CAMPAIGN_EDIT: "/campaigns/{campaign}",
  ISSUE_PRODUCT: "/campaigns/{campaign}/issue",
  UPLOAD_CAMPAIGN_COVER: "/campaigns/{campaign}/picture/upload",
  UPLOAD_CAMPAIGN_ICON: "/campaigns/{campaign}/picture/upload?type=avatar",
  GET_CAMPAIGN_TERMS: "/campaigns/{campaign}/use-terms",
  GET_CAMPAIGN_PRIZES: "/campaigns/{campaign}/products/{product}/prizes",
  ACCEPT_PRODUCT: "/campaigns/{campaign}/products/accept",
  CAMPAIGN_RESERVATION: "/campaigns/{campaign}/reservations",

  GET_TRANSACTIONS: "/data/transactions",

  BURN_COUPON: "/products/coupon/{qr-code}/burn",
  CREATE_QRCODE_IMAGE: "/products/{qr-code}/qrcode-img",
  REDEEM_PRIZE: "/products/card/{card}/prize/{prize}",
  DECREASE_CARD_BALANCE: "/products/card/{qr-code}/decrease",
  INCREASE_CARD_BALANCE: "/products/card/{qr-code}/increase",
  CREATE_PERMISSION: "/products/{product}/qrcode",
  CREATE_MULTI_PERMISSION: "/products/qrcode",
  GET_EXTERNAL_CODE: "/products/{product}/external-id",

  RESERVATION: "/reservations/{reservation}",

  SIGNUP: "/signup",
  CUSTOM_SIGNUP: "/signup/custom",

  QUERY_POINTS_USERS: "/stats/points-users",

  GET_TENANT_BUSINESS: "/tenant/business",
  GET_TENANT_PERMISSIONS: "/tenant/permissions",
  GET_TENANT_CAMPAIGNS: "/tenant/campaigns",
  GET_TENANT_POLICY: "/tenant/policies",
  UPDATE_TENANT_POLICY: "/tenant/policies",
  SUPPORT_EMAIL: "/tenant/support/email",
  GET_CAMPAIGN: "/tenant/{tenant}/campaigns/{campaign}",
  GET_EVENTS: "/tenant/{tenant}/events",

  GET_USER: "/users/{user}",
  USER_PROFILE: "/users/{user}/profile",
  GET_USER_BUSINESS: "/users/{user}/business",
  GET_USER_PERMISSION: "/users/{user}/business/{business}/permissions",
  GET_PRODUCTS: "/users/{user}/products",
  GET_PRODUCTS_STATS: "/users/{user}/campaigns/stats",
  GET_DEFAULT_PRODUCT: "/users/{user}/products/default",
  GET_USER_RESERVATIONS: "/users/{user}/reservations",
  UPLOAD_AVATAR: "/users/{user}/picture/upload",
  UPDATE_LCNG_USER: "/users/lcng/{user}",
  CREATE_MANAGER: "/users/manager",
  USER_PERMISSION: "/users/permission/{permission}",

  CREATE_ORDER: "/operations/orders",
  CHECK_ORDER: "/operations/orders/{order}/check/{status}",
  GET_INVENTORIES: "/operations/orders/inventories",
};
