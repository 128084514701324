import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, LocalMall } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import { CONSUMER_ROUTES } from "../../../utils/constants";
import { useBmapi } from "../../../utils/bmapi-context";
import Title from "../../../ui/Title";

import countries from "./countries.json";
import province from "./province.json";
import { getErrorMessageString } from "../../../utils/errors";

export default function LuccaBuy() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const [dirty, setDirty] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [values, setValues] = useState({
    address: "",
    business_id: "",
    business_name: "",
    city: "",
    country: "",
    direct_marketing: null,
    indirect_marketing: null,
    last_name: "",
    mobile: "",
    name: "",
    newsletter: null,
    order_settings_id: "",
    pec: "",
    privacy: null,
    province: "",
    receipt: false,
    sdi: "",
    status: "STANDBY",
    user_id: bmapi.getUserInfo().user_id,
    vat_number: "",
    zip: "",
  });
  const [errors, setErrors] = useState({
    privacy: false,
  });

  const handleChange = (label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
      setDirty(true);
    };

    switch (label) {
      case "country":
        return (_, v) => updateValue(v && v.name);

      case "business_id":
        return (_, v) => {
          updateValue((v && v.id) || "");
          handleChange("order_settings_id")(false, "");
        };

      case "indirect_marketing":
      case "direct_marketing":
      case "newsletter":
      case "privacy":
        return (_, v) => updateValue(JSON.parse(v));

      case "order_settings_id":
      case "receipt":
        return (_, v) => updateValue(v);

      default:
        return (i) => updateValue(i?.target ? i.target.value : i);
    }
  };

  useEffect(() => {
    startLoading();
    Promise.all([bmapi.getTenantBusiness(), bmapi.getInventories()]).then(
      ([bs, inv]) => {
        setBusinesses(
          bs
            .sort(
              (a, b) =>
                a.region.localeCompare(b.region) || a.name.localeCompare(b.name)
            )
            .map((bs) => ({
              ...bs,
              availabilityBase: 0,
              // availabilityBase: (inv || []).find(
              //   (i) =>
              //     i.b_id === bs.id &&
              //     i.order_settings_id === bmapi.settings.orderSettingsIDBase
              // ),
              availabilityGoody: (inv || []).find(
                (i) =>
                  i.b_id === bs.id &&
                  i.order_settings_id === bmapi.settings.orderSettingsIDGoody
              ),
            }))
        );
        stopLoading();
      }
    );
  }, [bmapi, startLoading, stopLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({
      privacy: !values.privacy,
    });

    if (!values.privacy) {
      return notifyError(
        "Per proseguire è necessario acconsentire il trattamento dei dati personali per l'erogazione del servizio"
      );
    }

    startLoading();

    bmapi
      .createOrder(values)
      .then((res) => {
        setDirty(false);
        window.location.href = res.url;
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  const availabilityBase = businesses.find((bs) => bs.id === values.business_id)
    ?.availabilityBase;
  const availabilityGoody = businesses.find(
    (bs) => bs.id === values.business_id
  )?.availabilityGoody;

  return (
    <Container maxWidth="sm">
      <Title backUrl={CONSUMER_ROUTES.HOME}>Acquista il tuo Pass</Title>

      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <Autocomplete
                options={businesses}
                getOptionLabel={(option) => `${option.name} - ${option.city}`}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.name} - {option.city}{" "}
                    {!option.availabilityBase?.available &&
                    !option.availabilityGoody?.available ? (
                      "[Posti esauriti]"
                    ) : option.availabilityGoody?.available ? (
                      <LocalMall
                        color="primary"
                        style={{ fontSize: 18, marginLeft: 10 }}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                getOptionDisabled={(option) =>
                  !option.availabilityBase?.available &&
                  !option.availabilityGoody?.available
                }
                groupBy={(option) => option.region}
                onChange={handleChange("business_id")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="business_id"
                    label="Negozio di riferimento"
                    value={values.business_id}
                    fullWidth
                    margin="normal"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "chrome-off",
                    }}
                  />
                )}
              />

              <Collapse in={!!values.business_id}>
                <FormControl margin="normal" fullWidth>
                  <FormLabel>
                    <Typography variant="caption" display="block" gutterBottom>
                      Scegli il tuo pass *
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="orderSettingId"
                    value={values.order_settings_id}
                    onChange={handleChange("order_settings_id")}
                  >
                    <FormControlLabel
                      value={bmapi.settings.orderSettingsIDBase}
                      control={<Radio required />}
                      disabled={
                        !availabilityBase || !availabilityBase.available
                      }
                      label={`Campfire Pass ${
                        availabilityBase?.available || !values.business_id
                          ? "• 3€"
                          : "[pass esauriti]"
                      }`}
                    />
                    <FormControlLabel
                      value={bmapi.settings.orderSettingsIDGoody}
                      control={<Radio required />}
                      disabled={
                        !availabilityGoody || !availabilityGoody.available
                      }
                      label={`Campfire Pass + Bag of Lucca ${
                        availabilityGoody?.available || !values.business_id
                          ? "• 8€"
                          : "[pass esauriti]"
                      }`}
                    />
                  </RadioGroup>
                </FormControl>
              </Collapse>
            </CardContent>
          </Card>
        </Box>

        <Typography variant="h5" display="block" gutterBottom>
          Anagrafica
        </Typography>

        <Box mb={2}>
          <Card>
            <CardContent>
              <TextField
                name="name"
                label="Nome"
                value={values.name}
                onChange={handleChange("name")}
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 150 }}
              />

              <TextField
                name="last_name"
                label="Cognome"
                value={values.last_name}
                onChange={handleChange("last_name")}
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 150 }}
              />

              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.name}
                onChange={handleChange("country")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="country"
                    label="Nazione"
                    value={values.country}
                    fullWidth
                    margin="normal"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "chrome-off",
                    }}
                  />
                )}
              />

              <TextField
                name="address"
                label="Indirizzo di residenza"
                value={values.address}
                onChange={handleChange("address")}
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 150 }}
              />

              <TextField
                name="city"
                label="Città"
                value={values.city}
                onChange={handleChange("city")}
                fullWidth
                margin="normal"
                required
                inputProps={{ maxLength: 150 }}
              />

              <TextField
                name="zip"
                label="CAP"
                value={values.zip}
                onChange={handleChange("zip")}
                fullWidth
                margin="normal"
                required
                inputProps={
                  values.country === "Italia"
                    ? {
                        maxLength: 5,
                        pattern: "^[0-9]{5}$",
                      }
                    : {
                        maxLength: 10,
                      }
                }
              />

              {values.country === "Italia" ? (
                <Autocomplete
                  options={province}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, v) => handleChange("province")(v && v.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="province"
                      label="Provincia"
                      value={values.province}
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "chrome-off",
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  name="provinceText"
                  label="Provincia"
                  value={values.province}
                  onChange={handleChange("province")}
                  fullWidth
                  margin="normal"
                  required
                  inputProps={{ maxLength: 150 }}
                />
              )}

              <TextField
                name="mobile"
                label="Cellulare"
                value={values.mobile}
                onChange={handleChange("mobile")}
                fullWidth
                margin="normal"
                required
                type="tel"
                inputProps={{
                  maxLength: 15,
                  pattern: "^[+]?[0-9 ]{1,15}$",
                }}
                helperText="inserire il prefisso internazionale davanti al numero es: +39..."
              />

              <FormControl margin="normal" fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.receipt}
                      color="primary"
                      onChange={handleChange("receipt")}
                      name="receipt"
                    />
                  }
                  label="Voglio ricevere la ricevuta fiscale"
                />
              </FormControl>

              {values.country === "Italia" && values.receipt && (
                <TextField
                  name="vat_number"
                  label="Codice fiscale"
                  value={values.vat_number}
                  onChange={(e) =>
                    handleChange("vat_number")(e.target.value.toUpperCase())
                  }
                  fullWidth
                  margin="normal"
                  required
                  inputProps={{
                    pattern:
                      "^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$",
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Box>

        <Card>
          <CardContent>
            <Typography>
              Accedi{" "}
              <Link
                component={RouterLink}
                to={CONSUMER_ROUTES.STATIC.replace(":content?/", "pass-tos")}
                target="_blank"
              >
                qui
              </Link>{" "}
              per leggere la descrizione del trattamento effettuata sui tuoi
              dati personali.
            </Typography>

            <FormControl margin="normal" fullWidth error={!!errors.privacy}>
              <FormLabel component="legend">
                <Typography variant="body2">
                  Acconsento al{" "}
                  <strong>trattamento dei miei dati personali</strong>{" "}
                  <u>sopra</u> descritto{" "}
                  <strong>
                    al fine di consentire l’erogazione del servizio
                  </strong>
                  . <u>[Obbligatorio]</u>
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="privacy"
                value={JSON.stringify(values.privacy)}
                onChange={handleChange("privacy")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio required />}
                  label="Acconsento"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio required />}
                  label="Non acconsento"
                />
              </RadioGroup>
              {!!errors.privacy && (
                <FormHelperText>È necessario acconsentire</FormHelperText>
              )}
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <FormLabel component="legend">
                <Typography variant="body2">
                  Acconsento al trattamento dei miei dati personali <u>sopra</u>{" "}
                  descritto per{" "}
                  <strong>
                    finalità di marketing dirette da parte del circuito Campfire
                    e Retail First
                  </strong>{" "}
                  e/o degli esercenti a cui appartengono le promo e gli
                  strumenti di fidelizzazione utilizzati. A titolo di esempio
                  ricevere promozione{" "}
                  <strong>
                    iniziative da parte di Retail First, coupon di sconto
                    offerti dagli Sponsor
                  </strong>{" "}
                  degli eventi del Campfire. <u>[Facoltativo]</u>
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="direct_marketing"
                value={JSON.stringify(values.direct_marketing)}
                onChange={handleChange("direct_marketing")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio required />}
                  label="Acconsento"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio required />}
                  label="Non acconsento"
                />
              </RadioGroup>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <FormLabel component="legend">
                <Typography variant="body2">
                  Acconsento al trattamento dei miei dati personali <u>sopra</u>{" "}
                  descritto per{" "}
                  <strong>
                    finalità di marketing eseguite da terze parti collegate al
                    circuito Campfire
                  </strong>{" "}
                  e/o degli esercenti a cui appartengono le promo e gli
                  strumenti di fidelizzazione utilizzati. <u>[Facoltativo]</u>
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="indirect_marketing"
                value={JSON.stringify(values.indirect_marketing)}
                onChange={handleChange("indirect_marketing")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio required />}
                  label="Acconsento"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio required />}
                  label="Non acconsento"
                />
              </RadioGroup>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <FormLabel component="legend">
                <Typography variant="body2">
                  Acconsento all’
                  <strong>iscrizione alla newsletter di Lucca Crea</strong> per
                  essere aggiornato sul calendario degli eventi e sulle attività
                  della Società. <u>[Facoltativo]</u>
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="newsletter"
                value={JSON.stringify(values.newsletter)}
                onChange={handleChange("newsletter")}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio required />}
                  label="Acconsento"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio required />}
                  label="Non acconsento"
                />
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <Typography variant="caption" gutterBottom>
                <strong>Attenzione</strong>: dal momento in cui si clicca
                &quot;Vai al pagamento&quot; si hanno a disposizione 30 minuti
                per concludere l&apos;ordine
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={dirty && false}
              >
                Vai al pagamento
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </form>
      <Box mt={2}>
        <Button
          component={RouterLink}
          to={CONSUMER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          Torna alla home
        </Button>
      </Box>
    </Container>
  );
}
