import React, { forwardRef } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";

import { FEATURES, MANAGER_ROUTES, PRODUCTS } from "../utils/constants";
import { IconsMap, subtypeMap } from "../utils/campaigns";
import { useBmapi } from "../utils/bmapi-context";
import { campaign } from "../messages";

function CampaignMenuItemBase({ name, Icon, onClick }, ref) {
  const intl = useIntl();

  return (
    <MenuItem onClick={onClick} innerRef={ref}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={intl.formatMessage(
          {
            id: "component.createCampaignButton.createCampaignType",
            defaultMessage: "Crea una campagna di tipo {campaignType}",
          },
          { campaignType: name }
        )}
      />
    </MenuItem>
  );
}

const CampaignMenuItem = forwardRef(CampaignMenuItemBase);

export default function CreateCampaignButton({ trigger }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    handleClose();
    history.push(
      MANAGER_ROUTES.CREATE_CAMPAIGN.replace(":subtype?", subtype.toLowerCase())
    );
  };

  const buttons = [
    {
      name: intl.formatMessage(campaign.couponShort),
      type: PRODUCTS.COUPON_SIMPLE,
      feature: FEATURES.CAMPAIGN_COUPON,
    },
    {
      name: intl.formatMessage(campaign.fidelity),
      type: PRODUCTS.EARNING_CARD,
      feature: FEATURES.CAMPAIGN_EARNING_CARD,
    },
    {
      name: intl.formatMessage(campaign.giftcard),
      type: PRODUCTS.SHOPPING_CARD,
      feature: FEATURES.CAMPAIGN_SHOPPING_CARD,
    },
    {
      name: intl.formatMessage(campaign.provisioning),
      type: PRODUCTS.PROVISIONING_CARD,
      feature: FEATURES.CAMPAIGN_PROVISIONING_CARD,
    },
    {
      name: intl.formatMessage(campaign.eventPass),
      type: PRODUCTS.EVENT_PASS,
      feature: FEATURES.CAMPAIGN_EVENT_PASS,
    },
  ]
    .filter((b) => bmapi.can(b.feature))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          {buttons.map((button) => (
            <CampaignMenuItem
              name={button.name}
              key={button.name}
              Icon={IconsMap[subtypeMap[button.type]]}
              onClick={() => create(button.type)}
            />
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
