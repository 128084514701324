import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  List,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Adjust as AdjustIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Person as PersonIcon,
  SwapHoriz as SwapHorizIcon,
} from "@material-ui/icons";

import ListItemInfo from "../ListItemInfo";
import { useBmapi } from "../../utils/bmapi-context";
import { TX_TYPES, VARIANTS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function CouponActionForm({
  info,
  owner,
  qrCode,
  transactions,
}) {
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [isBurning, setIsBurning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    setIsBurning(true);
  }

  const burn = useCallback(() => {
    startLoading();

    bmapi
      .burnCoupon(qrCode)
      .then(() => setSuccess(true))
      .catch((e) => setError(getErrorMessageString(e, intl)))
      .finally(() => {
        stopLoading();
        setIsBurning(false);
      });
  }, [bmapi, intl, qrCode, setError, setSuccess, startLoading, stopLoading]);

  useEffect(() => {
    if (isBurning) burn();
  }, [isBurning, burn]);

  useEffect(() => {
    if (bmapi.settings.fastScan) setIsBurning(true);
  }, [bmapi.settings.fastScan]);

  const hasRestrictions = () =>
    !!info.business_restriction &&
    !info.business_restriction.includes(bmapi.getUserInfo().business.id);

  return (
    <React.Fragment>
      {hasRestrictions() && (
        <Box mb={2}>
          <Alert severity="warning">
            <FormattedMessage
              id="component.actionForm.wrongBusiness"
              defaultMessage="Coupon valido ma associato ad un altro punto vendita"
            />
          </Alert>
        </Box>
      )}
      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <List>
              <ListItemInfo
                Icon={ConfirmationNumberIcon}
                label={intl.formatMessage({
                  id: "common.product",
                  defaultMessage: "Prodotto",
                })}
                text={intl.formatMessage({
                  id: "common.campaign.coupon",
                  defaultMessage: "Coupon",
                })}
              />
              <ListItemInfo
                Icon={AdjustIcon}
                label={intl.formatMessage({
                  id: "common.campaign",
                  defaultMessage: "Campagna",
                })}
                text={info.campaign_name}
              />
              <ListItemInfo
                Icon={PersonIcon}
                label={intl.formatMessage({
                  id: "component.actionForm.issuedTo",
                  defaultMessage: "Emessa a",
                })}
                text={owner && owner.email}
              />
              <ListItemInfo
                Icon={SwapHorizIcon}
                label={intl.formatMessage({
                  id: "component.actionForm.lastTransactions",
                  defaultMessage: "Ultime transazioni",
                })}
                text={
                  transactions &&
                  transactions.filter((t) => t.type === TX_TYPES.BURN).length >
                    0 ? (
                    <React.Fragment>
                      {transactions
                        .filter((t) => t.type === TX_TYPES.BURN)
                        .map((t) => (
                          <span key={t.id} style={{ display: "block" }}>
                            <FormattedRelativeTime
                              value={
                                (new Date(t.timestamp) - Date.now()) / 1000
                              }
                              numeric="auto"
                              updateIntervalInSeconds={10}
                            />
                          </span>
                        ))}
                    </React.Fragment>
                  ) : (
                    <FormattedMessage
                      id="component.actionForm.noTransactions"
                      defaultMessage="Nessuna transazione ancora effettuata"
                    />
                  )
                }
              />
            </List>

            <FormControl fullWidth margin="normal">
              {!success && !error ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isBurning}
                >
                  <FormattedMessage
                    id="common.confirm"
                    defaultMessage="Conferma"
                  />
                </Button>
              ) : (
                <Alert
                  variant="filled"
                  severity={success ? VARIANTS.SUCCESS : VARIANTS.ERROR}
                >
                  {success ? (
                    <FormattedMessage
                      id="component.couponActionForm.burned"
                      defaultMessage="Coupon usato correttamente"
                    />
                  ) : (
                    error
                  )}
                </Alert>
              )}
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
