import { defineMessages } from "react-intl";

export const confirm = defineMessages({
  deleteManager: {
    id: "component.manageStores.confirm",
    defaultMessage: "Rimuovere il manager {email}?",
  },
  exitPrompt: {
    id: "component.form.exitPrompt",
    defaultMessage:
      "Ci sono modifiche non salvate. Sei sicuro di voler uscire?",
  },
});
