import React from "react";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";

import { Box, Typography } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

import ProductDetails from "./ProductDetails";
import { common } from "../messages";
import { PRODUCT_SUBTYPES } from "../utils/constants";

export default function CampaignPerformance({
  fromLoop,
  owner,
  type,
  performance,
  terms,
}) {
  const intl = useIntl();

  function getDetails(performance, terms, type, owner, fromLoop) {
    switch (type) {
      case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
        return [
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.issuedCards",
              defaultMessage: "Carte rilasciate",
            }),
            value: performance && performance.issued_qty,
          },
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.issuedPoints",
              defaultMessage: "Punti rilasciati",
            }),
            value: performance && performance.issued_value / 100,
          },
          ...(owner
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.balance",
                    defaultMessage: "Saldo",
                  }),
                  value:
                    performance &&
                    (performance.issued_value -
                      performance.used_value -
                      performance.expired_value) /
                      100,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.usedPoints",
                    defaultMessage: "Punti usati",
                  }),
                  value: performance && performance.used_value / 100,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.expiredPoints",
                    defaultMessage: "Punti scaduti",
                  }),
                  value: performance && performance.expired_value / 100,
                },
              ]
            : []),
          ...(owner && fromLoop
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.participatingStores",
                    defaultMessage: "Negozi aderenti",
                  }),
                  value: terms.length
                    ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                    : intl.formatMessage(common.all),
                },
              ]
            : []),
        ];
      case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
      case PRODUCT_SUBTYPES.PROVISIONING_CARD:
        return [
          ...(owner
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.issuedCards",
                    defaultMessage: "Carte rilasciate",
                  }),
                  value: performance && performance.issued_qty,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.remainingValue",
                    defaultMessage: "Valore residuo",
                  }),
                  value:
                    performance &&
                    (performance.issued_value -
                      performance.used_value -
                      performance.expired_value) /
                      100,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.expiredValue",
                    defaultMessage: "Valore scaduto",
                  }),
                  value: performance && performance.expired_value / 100,
                },
              ]
            : []),
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.usedValue",
              defaultMessage: "Valore usato",
            }),
            value: performance && performance.used_value / 100,
          },
          ...(owner && fromLoop
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.participatingStores",
                    defaultMessage: "Negozi aderenti",
                  }),
                  value: terms.length
                    ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                    : intl.formatMessage(common.all),
                },
              ]
            : []),
        ];
      case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
      case PRODUCT_SUBTYPES.COUPON_SIMPLE:
      case PRODUCT_SUBTYPES.COUPON_VALUE:
        return [
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.lastUsedCoupon",
              defaultMessage: "Ultimo coupon usato",
            }),
            value:
              !performance || !performance.last_tx ? (
                false
              ) : performance.last_tx.id ? (
                <FormattedRelativeTime
                  value={
                    (new Date(performance.last_tx.created_at) - Date.now()) /
                    1000
                  }
                  numeric="auto"
                  updateIntervalInSeconds={10}
                />
              ) : (
                "-"
              ),
          },
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.usedCoupons",
              defaultMessage: "Coupon usati",
            }),
            value: performance && performance.used_qty,
          },
          ...(owner
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.availablesCoupons",
                    defaultMessage: "Coupon disponibili",
                  }),
                  value:
                    performance &&
                    performance.issued_qty -
                      performance.used_qty -
                      performance.expired_qty,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.issuedCoupons",
                    defaultMessage: "Coupon rilasciati",
                  }),
                  value: performance && performance.issued_qty,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.expiredCoupons",
                    defaultMessage: "Coupon scaduti",
                  }),
                  value: performance && performance.expired_qty,
                },
              ]
            : []),
          ...(owner && fromLoop
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.participatingStores",
                    defaultMessage: "Negozi aderenti",
                  }),
                  value: terms.length
                    ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                    : intl.formatMessage(common.all),
                },
              ]
            : []),
        ];

      case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
        return [
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.lastUsedPass",
              defaultMessage: "Ultimo pass usato",
            }),
            value:
              !performance || !performance.last_tx ? (
                false
              ) : performance.last_tx.id ? (
                <FormattedRelativeTime
                  value={
                    (new Date(performance.last_tx.created_at) - Date.now()) /
                    1000
                  }
                  numeric="auto"
                  updateIntervalInSeconds={10}
                />
              ) : (
                "-"
              ),
          },
          {
            label: intl.formatMessage({
              id: "component.campaignPerformance.usedPasses",
              defaultMessage: "Pass usati",
            }),
            value: performance && performance.used_qty,
          },
          ...(owner
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.availablesPasses",
                    defaultMessage: "Pass disponibili",
                  }),
                  value:
                    performance &&
                    performance.issued_qty -
                      performance.used_qty -
                      performance.expired_qty,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.issuedPasses",
                    defaultMessage: "Pass rilasciati",
                  }),
                  value: performance && performance.issued_qty,
                },
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.expiredPasses",
                    defaultMessage: "Pass scaduti",
                  }),
                  value: performance && performance.expired_qty,
                },
              ]
            : []),
          ...(owner && fromLoop
            ? [
                {
                  label: intl.formatMessage({
                    id: "component.campaignPerformance.participatingStores",
                    defaultMessage: "Negozi aderenti",
                  }),
                  value: terms.length
                    ? `${terms.filter((t) => t.signed).length}/${terms.length}`
                    : intl.formatMessage(common.all),
                },
              ]
            : []),
        ];

      default:
        break;
    }

    return [];
  }

  return (
    <React.Fragment>
      <Box mt={1} mb={1}>
        {getDetails(performance, terms, type, owner, fromLoop).map((d) => (
          <ProductDetails key={d.label} label={d.label} value={d.value} />
        ))}
      </Box>

      {fromLoop ? (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          <InfoIcon
            fontSize="small"
            color="inherit"
            style={{ verticalAlign: "text-bottom", marginRight: 4 }}
          />
          {owner ? (
            <FormattedMessage
              id="component.campaignPerformance.allStores"
              defaultMessage="Le statistiche visualizzate sono quelle generate da tutti i punti vendita"
            />
          ) : (
            <FormattedMessage
              id="component.campaignPerformance.onlyMine"
              defaultMessage="Le statistiche visualizzate sono solo quelle generate dal punto vendita"
            />
          )}
        </Typography>
      ) : null}
    </React.Fragment>
  );
}
