import React from "react";

import {
  AccountCircle as AccountCircleIcon,
  Apps,
  CenterFocusStrong as CenterFocusStrongIcon,
  CloudDownload as CloudDownloadIcon,
  Equalizer as EqualizerIcon,
  ExitToApp as ExitIcon,
  Home as HomeIcon,
  Loyalty as LoyaltyIcon,
  OpenInNew,
  Send as SendIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";

import Account from "../pages/Account";
import Action from "../pages/Action";
import Activation from "../pages/Activation";
import Calendar from "../pages/consumer/Calendar";
import Campaign from "../pages/consumer/Campaign";
import CampaignManager from "../pages/manager/Campaign";
import CampaignClone from "../pages/manager/CampaignClone";
import CampaignCreate from "../pages/manager/CampaignCreate";
import CampaignEdit from "../pages/manager/CampaignEdit";
import Campaigns from "../pages/manager/Campaigns";
import Dashboard from "../pages/manager/Dashboard";
import Entry from "../pages/Entry";
import Export from "../pages/manager/Export";
import Home from "../pages/manager/Home";
import Manage from "../pages/manager/Manage";
import PasswordForget from "../pages/PasswordReset";
import Products from "../pages/consumer/Products";
import QrCode from "../pages/consumer/QrCode";
import Reservations from "../pages/manager/Reservations";
import Checkin from "../pages/consumer/Scanner";
import Scanner from "../pages/manager/Scanner";
import SendPoints from "../pages/manager/SendPoints";
import SignIn from "../pages/consumer/SignIn";
import SignInManager from "../pages/manager/SignIn";
import SignUp from "../pages/SignUp";
import Static from "../pages/Static";

import LuccaBuy from "../pages/custom/lcng/LuccaBuy";
import LuccaConfirm from "../pages/custom/lcng/LuccaConfirm";
import LuccaStats from "../pages/custom/lcng/LuccaStats";

import PrivatePage from "../components/PrivatePage";
import PublicPage from "../components/PublicPage";

import {
  CONSUMER_ROUTES,
  CONSUMER,
  FEATURES,
  LINK_TYPE,
  MANAGER_ROUTES,
  MANAGER,
  ROLES,
  TENANTS,
  USER_STATUS,
} from "../utils/constants";
import { navigation } from "../messages";

export default (bmapi, app, status) => {
  function canView(link) {
    return (
      !link.disabled &&
      (!link.feature || bmapi.can(link.feature)) &&
      (!link.roles ||
        bmapi.validateRoles(link.roles, bmapi.getUserInfo().role)) &&
      (!link.tenants || link.tenants.includes(bmapi.tenant))
    );
  }

  const logoutLink = {
    feature: FEATURES.LOGOUT,
    menu: {
      action: () => bmapi.logout(),
      icon: <ExitIcon />,
      label: navigation.logout,
      position: LINK_TYPE.ACTION,
      sublabel: bmapi.getUserInfo().token_info.email,
    },
  };

  const actionPage = {
    routing: {
      Component: Action,
      path: CONSUMER_ROUTES.ACTION,
      Wrapper: PublicPage,
    },
  };

  const campaignPage = {
    routing: {
      Component: Campaign,
      path: CONSUMER_ROUTES.CAMPAIGN,
      Wrapper: PrivatePage,
    },
  };

  const calendarPage = {
    routing: {
      Component: Calendar,
      path: CONSUMER_ROUTES.CALENDAR,
      Wrapper: PrivatePage,
    },
  };

  const staticPage = {
    routing: {
      Component: Static,
      path: CONSUMER_ROUTES.STATIC,
      Wrapper: PrivatePage,
    },
  };

  const staticManagerPage = {
    routing: {
      Component: Static,
      path: MANAGER_ROUTES.STATIC,
      Wrapper: PrivatePage,
    },
  };

  const ekomLink = {
    menu: {
      icon: <OpenInNew />,
      label: "www.ekomdiscount.it",
      position: LINK_TYPE.PAGE,
      url: "http://www.ekomdiscount.it/",
    },
    tenants: [TENANTS.EKOM],
  };

  const pages = {
    [CONSUMER]: {
      [USER_STATUS.ANONYMOUS]: [
        {
          routing: {
            Component: SignIn,
            path: CONSUMER_ROUTES.SIGN_IN_ACTION,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: SignIn,
            path: CONSUMER_ROUTES.SIGN_IN,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: SignUp,
            path: CONSUMER_ROUTES.SIGN_UP,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: PasswordForget,
            path: CONSUMER_ROUTES.PASSWORD_FORGET,
            Wrapper: PublicPage,
          },
        },
        {
          home: true,
          routing: {
            Component: Entry,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
            Wrapper: PublicPage,
          },
        },
        campaignPage,
        calendarPage,
        staticPage,
        actionPage,
        ekomLink,
      ],
      [USER_STATUS.ACTIVATION]: [
        {
          home: true,
          routing: {
            Component: Activation,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
            Wrapper: PrivatePage,
          },
        },
        logoutLink,
        staticPage,
        actionPage,
        ekomLink,
      ],
      [USER_STATUS.LOGGED]: [
        {
          home: true,
          menu: {
            icon: <HomeIcon />,
            label: navigation.home,
            position: LINK_TYPE.PAGE,
            url: CONSUMER_ROUTES.HOME,
          },
          routing: {
            Component: Products,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.MY_CODE,
          menu: {
            icon: <Apps />,
            label: navigation.qrcode,
            url: CONSUMER_ROUTES.QR_CODE,
            position: LINK_TYPE.PAGE,
          },
          routing: {
            Component: QrCode,
            path: CONSUMER_ROUTES.QR_CODE,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.CHECKIN,
          menu: {
            icon: <CenterFocusStrongIcon />,
            label: navigation.checkin,
            position: LINK_TYPE.PAGE,
            url: CONSUMER_ROUTES.SCAN,
          },
          routing: {
            Component: Checkin,
            path: CONSUMER_ROUTES.SCAN,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.ACCOUNT,
          menu: {
            icon: <AccountCircleIcon />,
            label: navigation.account,
            position: LINK_TYPE.ACTION,
            url: CONSUMER_ROUTES.ACCOUNT,
          },
          routing: {
            Component: Account,
            path: CONSUMER_ROUTES.ACCOUNT,
            Wrapper: PrivatePage,
          },
        },
        logoutLink,
        campaignPage,
        calendarPage,
        staticPage,
        actionPage,
        ekomLink,
        {
          menu: {
            icon: <AccountCircleIcon />,
            label: navigation.campfireFaq,
            position: LINK_TYPE.PAGE,
            url: CONSUMER_ROUTES.STATIC.replace(":content?/", "faq"),
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          routing: {
            Component: LuccaBuy,
            path: CONSUMER_ROUTES.LUCCA_BUY,
            Wrapper: PrivatePage,
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          routing: {
            Component: LuccaConfirm,
            path: CONSUMER_ROUTES.LUCCA_CONFIRM,
            Wrapper: PrivatePage,
          },
          tenants: [TENANTS.LUCCA],
        },
      ],
    },
    [MANAGER]: {
      [USER_STATUS.ANONYMOUS]: [
        {
          routing: {
            Component: SignInManager,
            path: MANAGER_ROUTES.SIGN_IN_ACTION,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: SignInManager,
            path: MANAGER_ROUTES.SIGN_IN,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: SignUp,
            path: MANAGER_ROUTES.SIGN_UP,
            Wrapper: PublicPage,
          },
        },
        {
          routing: {
            Component: PasswordForget,
            path: MANAGER_ROUTES.PASSWORD_FORGET,
            Wrapper: PublicPage,
          },
        },
        {
          home: true,
          routing: {
            Component: Entry,
            exact: true,
            path: MANAGER_ROUTES.HOME,
            Wrapper: PublicPage,
          },
        },
        staticManagerPage,
      ],
      [USER_STATUS.ACTIVATION]: [
        {
          home: true,
          routing: {
            Component: Activation,
            exact: true,
            path: MANAGER_ROUTES.HOME,
            Wrapper: PrivatePage,
          },
        },
        staticManagerPage,
        logoutLink,
      ],
      [USER_STATUS.LOGGED]: [
        {
          feature: FEATURES.VIEW_DASHBOARD,
          home: true,
          menu: {
            icon: <EqualizerIcon />,
            label: navigation.dashboard,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.DASHBOARD,
          },
          routing: {
            Component: Dashboard,
            path: MANAGER_ROUTES.DASHBOARD,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.VIEW_CAMPAIGNS,
          home: !bmapi.can(FEATURES.VIEW_DASHBOARD),
          menu: {
            icon: <LoyaltyIcon />,
            label: navigation.campaigns,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""),
          },
          routing: {
            Component: Campaigns,
            path: MANAGER_ROUTES.CAMPAIGNS,
            Wrapper: PrivatePage,
          },
        },
        {
          home: !bmapi.can(FEATURES.VIEW_CAMPAIGNS),
          routing: {
            Component: Home,
            exact: true,
            path: MANAGER_ROUTES.HOME,
            Wrapper: PrivatePage,
          },
        },
        {
          routing: {
            Component: CampaignManager,
            path: MANAGER_ROUTES.CAMPAIGN,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignCreate,
            path: MANAGER_ROUTES.CREATE_CAMPAIGN,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignEdit,
            path: MANAGER_ROUTES.EDIT_CAMPAIGN,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignClone,
            path: MANAGER_ROUTES.CLONE_CAMPAIGN,
            Wrapper: PrivatePage,
          },
        },
        {
          routing: {
            Component: Reservations,
            path: MANAGER_ROUTES.RESERVATIONS,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.SCAN_QR,
          menu: {
            icon: <CenterFocusStrongIcon />,
            label: navigation.scanner,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.SCAN,
          },
          roles: [ROLES.STORE_MANAGER],
          routing: {
            Component: Scanner,
            path: MANAGER_ROUTES.SCAN,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.SEND_POINTS,
          menu: {
            icon: <SendIcon />,
            label: navigation.sendPoints,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.SEND_POINTS,
          },
          routing: {
            Component: SendPoints,
            path: MANAGER_ROUTES.SEND_POINTS,
            Wrapper: PrivatePage,
          },
        },
        {
          feature: FEATURES.EXPORT,
          menu: {
            icon: <CloudDownloadIcon />,
            label: navigation.export,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.EXPORT,
          },
          routing: {
            Component: Export,
            path: MANAGER_ROUTES.EXPORT,
            Wrapper: PrivatePage,
          },
        },
        {
          menu: {
            label: navigation.campfireStats,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.LUCCA_STATS,
          },
          routing: {
            Component: LuccaStats,
            path: MANAGER_ROUTES.LUCCA_STATS,
            Wrapper: PrivatePage,
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          feature: FEATURES.MANAGE_SETTINGS,
          menu: {
            icon: <SettingsIcon />,
            label: navigation.tenantSettings,
            position: LINK_TYPE.PAGE,
            url: MANAGER_ROUTES.MANAGE,
          },
          routing: {
            Component: Manage,
            path: MANAGER_ROUTES.MANAGE,
            Wrapper: PrivatePage,
          },
          roles: [ROLES.TENANT_MANAGER],
        },
        {
          menu: {
            icon: <AccountCircleIcon />,
            label: navigation.account,
            position: LINK_TYPE.ACTION,
            url: MANAGER_ROUTES.ACCOUNT,
          },
          routing: {
            Component: Account,
            path: MANAGER_ROUTES.ACCOUNT,
            Wrapper: PrivatePage,
          },
        },
        staticManagerPage,
        logoutLink,
      ],
    },
  };

  return pages[app || bmapi.app][status || bmapi.userStatus].filter(canView);
};
