import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";

import { useBmapi } from "./bmapi-context";
import { getAppMessages, getLanguageWithoutRegionCode } from "./dictionaries";

const ProviderBase = ({ children, language, defaultLang, skin, tenant }) => (
  <IntlProvider
    locale={language}
    defaultLocale={defaultLang}
    messages={getAppMessages(language, defaultLang, skin, tenant)}
  >
    {children}
  </IntlProvider>
);
const Provider = React.memo(ProviderBase);

export function LocalizationProvider({ children }) {
  const { bmapi, userData } = useBmapi();

  const lang = useMemo(() => {
    return (
      userData?.language ||
      (bmapi?.settings.languages.includes(
        getLanguageWithoutRegionCode(navigator.language)
      )
        ? getLanguageWithoutRegionCode(navigator.language)
        : bmapi?.language)
    );
  }, [bmapi?.language, bmapi?.settings.languages, userData?.language]);

  return (
    bmapi && (
      <Provider
        language={
          bmapi?.settings.languages.includes(lang) ? lang : bmapi?.language
        }
        defaultLang={bmapi?.language}
        skin={bmapi?.settings.skin}
        tenant={bmapi?.tenant}
      >
        {children}
      </Provider>
    )
  );
}
